import ReactDOM from 'react-dom'
import dayjs from 'dayjs';

export const CustomTooltip = ({ tooltipData, label = "Date & Time :" }) => {
    if (!tooltipData || !tooltipData.body) return null;

    const { title, body } = tooltipData;
    return (
        <div style={{ background: '#fff', padding: '10px', borderRadius: '4px' }} className="shadow-lg">
            <h4 style={{ margin: 0, fontSize: '9x', color: "black", textAlign: "center" }}>{label} {title}</h4>
            <table style={{ width: '100%', marginTop: '10px', border: "1px solid #E6E6E6", borderRadius: "40px" }} className='border '>
                <thead className='bg-[#E6E6E6]'>
                    <tr>
                        <th style={{ textAlign: 'left', padding: '5px', borderBottom: '1px solid #ccc', borderRight: '1px solid #ccc', color: "black", fontSize: "12px" }}>
                            Parameter
                        </th>
                        <th style={{ textAlign: 'left', padding: '5px', borderBottom: '1px solid #ccc', fontSize: "12px", color: "black" }}>
                            Value
                        </th>
                    </tr>
                </thead>
                <tbody style={{ fontSize: "12px", color: "black" }}>
                    {body.map((item, index) => {
                        const [parameter, value] = item.split(": ");


                        return (
                            <tr key={index}>
                                <td style={{ padding: '5px', borderBottom: '1px solid #eee', borderRight: '1px solid #eee' }}>
                                    {parameter}
                                </td>
                                <td style={{ padding: '5px', borderBottom: '1px solid #eee' }}>
                                    {value}
                                </td>
                            </tr>
                        );
                    })}

                </tbody>
            </table>

        </div>
    );
};


export const externalTooltipHandler = (context, tooltipRef,sethoveredPoint) => {
    const { chart, tooltip } = context;

    if (!tooltip || !tooltip.opacity) {
        tooltipRef.current.style.opacity = 0;
        return;
    }

    const tooltipData = {
        title: "",
        body: [],
    };


    tooltip.dataPoints.forEach((activePoint) => {
        const datasetIndex = activePoint.datasetIndex;
        const dataset = chart.data.datasets[datasetIndex];
        const label = activePoint.label;
        const logScaleVisible = dataset?.selectedFormat === "logarithmic" || dataset?.selectedFormat === "exponential";


        const yValue = activePoint.raw.y;

        const formattedYValue = logScaleVisible
            ? parseFloat(yValue).toExponential().replace(/(\.\d{2})\d+e/, '$1e')
            : dataset?.selectedFormat?.toLowerCase() === "real" ? (Math.trunc(Number(yValue) * 100) / 100).toFixed(2)
            : Math.trunc(Number(yValue));
        tooltipData.body.push(
            `${dataset.label}: ${formattedYValue}`
        );

        tooltipData.title = activePoint.raw.x
            ? `${dayjs(activePoint.raw.x).format("DD/MM/YYYY HH:mm")}`
            : ''


    });

sethoveredPoint(tooltipData)

    const tooltipWidth = tooltipRef.current.offsetWidth;
    const chartWidth = chart.canvas.width;
    const chartHeight = chart.canvas.height;
    const tooltipHeight = tooltipRef.current.offsetHeight;

    let tooltipLeft = chart.canvas.offsetLeft + tooltip.caretX;
    const tooltipVerticalCenter = (chartHeight - tooltipHeight) / 2;

    if (tooltipLeft + tooltipWidth > chartWidth) {
        tooltipLeft = chart.canvas.offsetLeft + chartWidth - tooltipWidth - 10;
    }

    if (tooltipLeft < chart.canvas.offsetLeft) {
        tooltipLeft = chart.canvas.offsetLeft + 10;
    }

    tooltipRef.current.style.opacity = 1;
    tooltipRef.current.style.left = `${tooltipLeft}px`;
    tooltipRef.current.style.top = `${chart.canvas.offsetTop + tooltipVerticalCenter}px`;

};
import React, { useEffect, useState } from "react";
import BatchDetails from "./BatchDetails";
import BatchComparison from "./BatchComparison";

import getAPIMap from "../routes/url/ApiUrls";
import { useAxiosPrivate } from "../hooks/useAxiosPrivate";

const tabs = [
  {
    key: "batchDetails",
    label: "Batch Details",
    component: BatchDetails,
  },
  {
    key: "batchComparison",
    label: "Batch Comparison",
    component: BatchComparison,
  },
];

const Home = () => {
  const axiosPrivate = useAxiosPrivate();
  const [selectedOption, setSelectedOption] = useState("batchDetails");
  const [masterBatch, setMasterBatch] = useState(false);
  const [toolTip, setToolTip] = useState("Master batch not set. Please set master batch to view batch comparison!");
  const [showTooltip, setShowTooltip] = useState(false);

  const handleTabChange = (key) => {
    if (key === "batchComparison" && !masterBatch) return;
    setSelectedOption(key);
  };

  useEffect(() => {
    const getMasterBatch = async () => {
      try {
        const response = await axiosPrivate.get(getAPIMap("mastBatchPath"));
        if (response.status === 200 && response.data.is_master_batch) {
          setMasterBatch(true);
        }
      } catch (error) {
        setMasterBatch(false);
      }
    };
    getMasterBatch();
  }, [axiosPrivate]);

  const renderActiveComponent = () => {
    const activeTab = tabs.find((tab) => tab.key === selectedOption);
    return activeTab?.component ? <activeTab.component /> : null;
  };

  return (
    <div className="h-full flex flex-col ml-4 relative">
      <div className="w-4/12 h-8 flex justify-center items-center">
        {tabs.map((tab) => {
          const isDisabled = tab.key === "batchComparison" && !masterBatch;

          return (
            <div
              key={tab.key}
              className={`${selectedOption === tab.key
                ? "bg-white rounded-t-md text-textGray font-semibold text-[12px]/[16px] border"
                : "text-white font-semibold text-[12px]/[16px]"}
                ${isDisabled ? "opacity-60 cursor-pointer relative" : "cursor-pointer"}
                w-1/2 h-full flex justify-center items-center relative`}
              onClick={() => handleTabChange(tab.key)}
              onMouseEnter={() => isDisabled && setShowTooltip(true)}
              onMouseLeave={() => setShowTooltip(false)}
            >
              {tab.label}

              {/* Tooltip */}
              {isDisabled && showTooltip && (
                <div 
                  className="absolute -top-2 left-80 ml-4 transform -translate-x-1/2 mt-2 p-2 bg-black text-white text-xs rounded shadow-lg w-max"
                  style={{ zIndex: 9999 }}
                >
                  {toolTip}
                </div>
              )}
            </div>
          );
        })}
      </div>

      <div className="mr-2">{renderActiveComponent()}</div>
    </div>
  );
};

export default Home;

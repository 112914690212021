import React, {
    forwardRef,
    useImperativeHandle,
    useRef,
} from "react";
import { Line } from "react-chartjs-2";
import { CustomTooltip } from "../LineChart/CustomToolTip";
import ReactDOM from 'react-dom'
import dayjs from "dayjs";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import zoomPlugin from "chartjs-plugin-zoom";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import { prepareChartData } from "../../features/batch-comparison/utils/batch-comparison.utils";
import { externalTooltipHandler } from "../../features/batch-comparison/utils/batch-comparison.utils";
ChartJS.register(
    zoomPlugin,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

// const intersectDataVerticalLine = {
//     id: "intersectDataVerticalLine",
//     beforeDraw: (chart) => {
//         const activeElements = chart.getActiveElements();
//         const chartArea = chart.chartArea;

//         if (activeElements.length && chartArea) {
//             const activePoint = activeElements[0];
//             const { x } = activePoint.element;

//             if (x >= chartArea.left && x <= chartArea.right) {
//                 const ctx = chart.ctx;

//                 ctx.save();
//                 ctx.beginPath();
//                 ctx.setLineDash([5, 5]);
//                 ctx.moveTo(x, chartArea.top);
//                 ctx.lineTo(x, chartArea.bottom);
//                 ctx.lineWidth = 2;
//                 ctx.strokeStyle = "rgba(0, 0, 0, 1)";
//                 ctx.stroke();
//                 ctx.restore();
//             }
//         }
//     },
// };

// ChartJS.register(intersectDataVerticalLine);
let lastHoveredX = null; 
let hasHovered = false; 
const intersectDataVerticalLine = {
    id: 'intersectDataVerticalLine',
    beforeDraw: (chart) => {
        const activeElements = chart.getActiveElements();
        const chartArea = chart.chartArea;
        const ctx = chart.ctx;

        let x = null;

        if (activeElements.length && chartArea) {
            const activePoint = activeElements[0];
            x = activePoint.element.x;
            lastHoveredX = x; 
            hasHovered = true; 
        } else if (hasHovered && lastHoveredX !== null) {
            x = lastHoveredX; 
        }

        if (hasHovered && x !== null && x >= chartArea.left && x <= chartArea.right) {
            ctx.save();
            ctx.beginPath();
            ctx.moveTo(x, chartArea.top);
            ctx.lineTo(x, chartArea.bottom);
            ctx.lineWidth = 2;
            ctx.strokeStyle = 'rgba(0, 0, 0, 1)';
            ctx.stroke();
            
            ctx.restore();
        }
    }
};

ChartJS.register(intersectDataVerticalLine);



const FurnaceChart = forwardRef(
    (
        {
            data,
            chartRef,
            selectedPenId,
            minMaxValue,
            errorBand,
            yAxisFormat,
        sethoveredPoint

        },
        ref
    ) => {

        const tooltipRef = useRef(null);
        const valueKey = Object.keys(data?.[0]?.batchResult?.[0] || {}).filter(key => key !== "DATE_TIME" && key !== "BATCH_NUMBER" && key !== "minutesPassed" && key.toLowerCase() !== "date_time")?.[0] || ''

        const { labels, datasets, yAxisMin, yAxisMax, yAxisTicksCallback } = prepareChartData(
            {
                data,
                valueKey,
                errorBand,
                displayFormat: yAxisFormat?.toLowerCase(),
                min: minMaxValue?.min,
                max: minMaxValue?.max,
            }
        );
        const chartData = {
            labels,
            datasets,
        };

// export const externalTooltipHandler = (context, tooltipRef,sethoveredPoint) => {
//     const { chart, tooltip } = context;

//     if (!tooltip || !tooltip.opacity) {
//         tooltipRef.current.style.opacity = 0;
//         return;
//     }

//     const tooltipData = {
//         title: "",
//         body: [],
//     };


//     tooltip.dataPoints.forEach((activePoint) => {
//         const datasetIndex = activePoint.datasetIndex;
//         const dataset = chart.data.datasets[datasetIndex];
//         const label = activePoint.label;
//         const logScaleVisible = dataset?.selectedFormat === "logarithmic" || dataset?.selectedFormat === "exponential";


//         const yValue = activePoint.raw.y;

//         const formattedYValue = logScaleVisible
//             ? parseFloat(yValue).toExponential().replace(/(\.\d{2})\d+e/, '$1e')
//             : dataset.selectedFormat.toLowerCase() === "real" ? Math.floor(Number(yValue) * 100) / 100 : Math.trunc(Number(yValue));
//         tooltipData.body.push(
//             `${dataset.label}: ${formattedYValue}`
//         );

//         tooltipData.title = activePoint.raw.x
//             ? `${dayjs(activePoint.raw.x).format("DD/MM/YYYY HH:mm")}`
//             : ''


//     });
// sethoveredPoint(tooltipData)

//     const tooltipWidth = tooltipRef.current.offsetWidth;
//     const chartWidth = chart.canvas.width;
//     const chartHeight = chart.canvas.height;
//     const tooltipHeight = tooltipRef.current.offsetHeight;

//     let tooltipLeft = chart.canvas.offsetLeft + tooltip.caretX;
//     const tooltipVerticalCenter = (chartHeight - tooltipHeight) / 2;

//     if (tooltipLeft + tooltipWidth > chartWidth) {
//         tooltipLeft = chart.canvas.offsetLeft + chartWidth - tooltipWidth - 10;
//     }

//     if (tooltipLeft < chart.canvas.offsetLeft) {
//         tooltipLeft = chart.canvas.offsetLeft + 10;
//     }

//     tooltipRef.current.style.opacity = 1;
//     tooltipRef.current.style.left = `${tooltipLeft}px`;
//     tooltipRef.current.style.top = `${chart.canvas.offsetTop + tooltipVerticalCenter}px`;

// };



        const options = {
            responsive: true,
            backgroundColor: "white",
            maintainAspectRatio: false,
            scales: {
                x: {
                    title: { display: true, text: "Time" },
                    type: "category",
                    // ticks: { autoSkip: false },
                },
                y: {
                    title: { display: true, text: selectedPenId?.pen_name },
                    // type: showLogScale ? 'logarithmic' : 'linear',
                    type: "linear", // Change to linear scale to support negative values
                    min: yAxisFormat.toLowerCase() === "exponential" ? yAxisMin.toExponential(2) : yAxisFormat.toLowerCase() === "real"?Number(yAxisMin.toFixed(2)):parseInt(Number(yAxisMin)),
                    max: yAxisFormat.toLowerCase() === "exponential" ? yAxisMax.toExponential(2) : yAxisFormat.toLowerCase() === "real"?Number(yAxisMax.toFixed(2)):parseInt(Number(yAxisMax)),
                    ticks: {
                        callback: yAxisTicksCallback
                    },
                },
            },
            plugins: {
                legend: {
                    display: false,
                },
                tooltip: {
                    enabled: false,
                external: (context) => externalTooltipHandler(context, tooltipRef,sethoveredPoint,yAxisFormat),
                },

                zoom: {
                    zoom: {
                        wheel: { enabled: true },
                        drag: {
                            enabled: true,
                            backgroundColor: "rgba(225, 225, 225, 0.3)",
                        },
                        mode: "xy",
                    },
                    pan: {
                        enabled: true,
                        mode: "xy",
                    },
                },
            },
            interaction: {
                mode: "index",
                intersect: false,
            },
            elements: {
                line: {
                    borderDash: [],
                },
            },
        };

        useImperativeHandle(ref, () => ({
            getChart: () => {
                return chartRef.current;
            },
            download: () => {
                const canvas = chartRef.current.canvas;
                canvas.style.backgroundColor = "white";
                const dataURL = canvas.toDataURL("image/jpeg", 1.0);
                const link = document.createElement("a");
                link.href = dataURL;
                link.download = "batchcomparisonchart.jpeg";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            },
            resetZoom: () => {
                const chartInstance = chartRef.current;
                if (chartInstance) {
                    chartInstance.resetZoom(); // Simply reset zoom
                    // setIsZoomedIn(false);
                }
            },
        }));

        return (
            <div className="w-full border rounded-md border-gray-100 p-2 h-[60vh] relative">
                <Line ref={chartRef} data={chartData} options={options} />
                <div
                    ref={tooltipRef}
                    style={{
                        position: 'absolute',
                        pointerEvents: 'none',
                        opacity: 0,
                    }}
                />

            </div>
        );
    }
);

export default React.memo(FurnaceChart);

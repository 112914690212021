import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import InputField from '../components/InputField';
import Select from '../components/SelectBox';
import { EyeClosedIcon, EyeOpenIcon } from '../assets/svgs';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAxiosPrivate } from '../hooks/useAxiosPrivate'
import getApiMap from '../routes/url/ApiUrls'
import Notification from '../../src/components/Notifications/index';
import { databaseOptions } from "../features/database-management/db-mgmt.constants";
import DbNotification from '../../src/components/Notifications/DbNotification';
import MandatoryLabel from "../components/common/MandatoryLabel";
import { config } from "../assets/config/config";

const { serverTypeMandatoryConfig } = config.database;

const DBConnection = () => {
    const axiosPrivate = useAxiosPrivate()

    const [notificationOpen, setNotificationOpen] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState('');
    const [notificationSeverity, setNotificationSeverity] = useState('success');
    const [message, setMessage] = useState('');
    const [dbsuccessMessage, setDbSuccessMessage] = useState(false)
    const [dbNotificationOpen, setDbNotificationOpen] = useState(false);
    const [dbNotificationMessage, setDbNotificationMessage] = useState('');
    const [dbNotificationSeverity, setDbNotificationSeverity] = useState('success');
    const [dbDetails, setDbDetails] = useState(null);
    const [showPassword, setShowPassword] = useState(false);
    const [isPasswordValidationVisible, setPasswordValidationVisible] = useState(false);
    const [serverType, setServerType] = useState(''); // State for selected server type  


    const navigate = useNavigate();

    // useEffect(() => {
    //     const getDataBaseTables = async () => {
    //         try {
    //             const response = await axiosPrivate.get(`${getApiMap("dataBaseTables")}`)
    //             const tables = response.data.tables.map((table) => {
    //                 return { label: table, value: table }
    //             })
    //             setTableOptions(tables)
    //         } catch (error) {
    //             console.log(error)
    //         }
    //     }
    //     getDataBaseTables()
    // }, [])

    const handleNotificationClose = () => {
        setNotificationOpen(false);
        setNotificationMessage('');
        if (notificationSeverity === "success") navigate("/");
    };

    const handleDbNotificationClose = () => {
        setDbNotificationOpen(false);
        setDbNotificationMessage('');
    };

    const getValidationSchema = (serverType) => {

        const validationSchema = {
            serverType: Yup.string()
                // .required("Server type is required")
                .min(1, "Server type is required"),
            path: Yup.string().min(1, "Path is required"),
            databaseName: Yup.string()
                //  .required("Database name is required")
                .min(1, "Database name is required"),
            userName: Yup.string().min(
                2, "Username must be at least 3 characters long"
            ),
            password: Yup.string()
                // .required("Password is required")
                .min(6, "Password must be at least 6 characters long"),
            port: Yup.string().matches(/^[0-9]+$/, "Port must be a valid number"),
            tableName: Yup.string()
                //    .required("Table name is required")
                .min(1, "Table name is required"),
            dateTimeColumn: Yup.string()
                //    .required("DateTime column name is required")
                .min(1, "DateTime column name is required"),
            batchNumberColumn: Yup.string()
                //    .required("Batch number column name is required")
                .min(1, "Batch number column name is required"),
            jobNumberColumn: Yup.string()
                //    .required("Job number column name is required")
                .min(1, "Job number column name is required"),
            Username_dateTimeColumn: Yup.string()
                //    .required("User name for DateTime column is required")
                .min(1, "User name for DateTime column is required"),
            Username_batchNumberColumn: Yup.string()
                //    .required("User name for Batch number column is required")
                .min(1, "User name for Batch number column is required"),
            Username_jobNumberColumn: Yup.string()
                //    .required("User name for Job number column is required")
                .min(1, "User name for Job number column is required"),
                MCGroupColumn:Yup.string()
                //    .required("This field is required")
                .min(1, "Tank Group Column name is required"),
                Username_MCGroupColumn: Yup.string()
                //    .required("This field is required")
                .min(1, "User name for MC group column is required"),
        };

        if (serverType === '') {
            validationSchema.serverType = Yup.string().required('This field is required');

        }
        if (serverType === 'MSAccess') {
            validationSchema.path = Yup.string().required('This field is required');
            validationSchema.databaseName = Yup.string().required('This field is required');
            validationSchema.tableName = Yup.string().required('This field is required');
            validationSchema.dateTimeColumn = Yup.string().required('This field is required');
            validationSchema.batchNumberColumn = Yup.string().required('This field is required');
            validationSchema.jobNumberColumn = Yup.string().required('This field is required');
            validationSchema.Username_dateTimeColumn = Yup.string().required('This field is required');
            validationSchema.Username_batchNumberColumn = Yup.string().required('This field is required');
            validationSchema.Username_jobNumberColumn = Yup.string().required('This field is required');
            validationSchema.Username_MCGroupColumn= Yup.string().required('This field is required');
            validationSchema.MCGroupColumn= Yup.string().required('This field is required');
        }

        if (serverType === 'MSSQL') {
            validationSchema.userName = Yup.string().required('User Name is required');
            validationSchema.password = Yup.string().required('Password is required');
            validationSchema.port = Yup.number().required('Port is required').positive().integer();
            validationSchema.path = Yup.string().required('This field is required');
            validationSchema.databaseName = Yup.string().required('This field is required');
            validationSchema.tableName = Yup.string().required('This field is required');
            validationSchema.dateTimeColumn = Yup.string().required('This field is required');
            validationSchema.batchNumberColumn = Yup.string().required('This field is required');
            validationSchema.jobNumberColumn = Yup.string().required('This field is required');
            validationSchema.Username_dateTimeColumn = Yup.string().required('This field is required');
            validationSchema.Username_batchNumberColumn = Yup.string().required('This field is required');
            validationSchema.Username_jobNumberColumn = Yup.string().required('This field is required');
            validationSchema.Username_MCGroupColumn= Yup.string().required('This field is required')
            validationSchema.MCGroupColumn= Yup.string().required('This field is required');
    
    
        }



        return Yup.object().shape(validationSchema);
    };

    // const databaseOptions = [
    //     { label: 'MSAccess', value: 'MSAccess' },
    //     { label: 'MSSQL', value: 'MSSQL' },
    // ];

    const navigateBack = () => {
        navigate('/')
    }

    const initialValues = {
        serverType: '',
        path: "",
        databaseName: '',
        userName: '',
        password: '',
        port: '',
        tableName: '',
        dateTimeColumn: '',
        batchNumberColumn: '',
        jobNumberColumn: '',
        Username_dateTimeColumn: '',
        Username_batchNumberColumn: '',
        Username_jobNumberColumn: '',
        Username_MCGroupColumn:'',
        MCGroupColumn:'',
    };

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: getValidationSchema(serverType), // Use dynamic schema  
        onSubmit: async (values, { setSubmitting }) => {
            try {
                const filteredValues = {
                    serverType: values.serverType,
                    path: values.path,
                    databaseName: values.databaseName,
                    dateTimeColumn: values.dateTimeColumn,
                    batchNumberColumn: values.batchNumberColumn,
                    jobNumberColumn: values.jobNumberColumn,
                    tableName: values.tableName,
                    Username_batchNumberColumn: values.Username_batchNumberColumn,
                    Username_dateTimeColumn: values.Username_dateTimeColumn,
                    Username_jobNumberColumn: values.Username_jobNumberColumn,
                    MCGroupColumn:values.MCGroupColumn,
                    Username_MCGroupColumn:values.Username_MCGroupColumn,
                    // Only include userName, password, and port if they are not empty  
                    ...(values.userName ? { userName: values.userName } : {}),
                    ...(values.password ? { password: values.password } : {}),
                    ...(values.port ? { port: values.port } : {}),
                };

                const response = await axiosPrivate.post(`${getApiMap("databaseBase")}`, filteredValues);
                if (response.status === 200) {
                    setNotificationOpen(true);
                    setNotificationMessage('Database connected successfully.');
                    setNotificationSeverity('success');
                    setDbDetails(response.data);
                }
            } catch (error) {
                if (error.response && error.response.status !== 200) {
                    setDbNotificationOpen(true);
                    setDbNotificationMessage('Failed to connect to the database. Please check your credentials.');
                    setDbNotificationSeverity('error');
                } else {
                    console.error("Error connecting to the database:", error);
                }
            } finally {
                setSubmitting(false);
            }
        },
    });


    const isMandatoryField = (field) => {
        return serverTypeMandatoryConfig?.[formik.values?.serverType]?.includes(
            field
        );
    };

    return (
        <>
            <div className="fixed inset-0 w-full h-screen flex items-center bg-opacity-80 bg-backgroundColor z-30 justify-center backdrop-blur-[5px]">
                <div className="w-7/12 bg-white p-8 rounded-md shadow-lg mt-16 overflow-y-auto max-h-[90vh] scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-200">
                    <div className="flex items-center relative">

                        <button onClick={() => { formik.resetForm(); navigateBack(); }} className="absolute top-0 right-0 text-gray-600 hover:text-gray-800 text-2xl">
                            &times;
                        </button>
                        <div className="text-textHeading2 pt-2 text-[16px]/[28px] font-bold">Connect to Database</div>
                    </div>
                    <div className="text-textGray text-[14px]/[20px] pt-1">Fill the form to connect the Db</div>

                    <form onSubmit={formik.handleSubmit} className="w-full mt-4">
                        <div className="w-full gap-x-4">
                            <div className="w-full grid grid-cols-2 gap-x-4">

                                <Select
                                    label={
                                        <MandatoryLabel
                                            name={"Database"}
                                            isMandatory={isMandatoryField("serverType")}
                                        />
                                    }
                                    name="serverType"
                                    options={databaseOptions}
                                    value={formik.values.serverType}
                                    onChange={(e) => {
                                        const selectedValue = e.target.value;
                                        setServerType(selectedValue); // Update the server type  

                                        formik.setFieldValue('serverType', selectedValue);
                                    }}
                                    error={
                                        formik.touched.serverType && formik.errors.serverType
                                    }
                                    placeholder="Choose database server"
                                    labelClass={styles.labelClass}
                                    inputClass={styles.inputClass}
                                />
                                <div className="mt-4">
                                    <InputField
                                        label={
                                            <MandatoryLabel
                                                name={"Database name"}
                                                isMandatory={isMandatoryField("databaseName")}
                                            />
                                        }
                                        //label="Database name"
                                        type="text"
                                        name="databaseName"
                                        value={formik.values.databaseName}
                                        onChange={formik.handleChange}
                                        error={formik.touched.databaseName && formik.errors.databaseName}
                                        labelClass={styles.labelClass}
                                        inputClass={styles.inputClass}
                                        placeholder="Enter Database name"
                                    />
                                </div>
                                <InputField
                                    label={
                                        <MandatoryLabel
                                            name={"Path"}
                                            isMandatory={isMandatoryField("path")}
                                        />
                                    }
                                    type="text"
                                    name="path"
                                    value={formik.values.path}
                                    onChange={formik.handleChange}
                                    error={formik.touched.path && formik.errors.path}
                                    labelClass={styles.labelClass}
                                    inputClass={styles.inputClass}
                                    placeholder="Enter Path"
                                />


                            </div>

                            <div className="w-full grid grid-cols-2 gap-x-4">

                                <InputField
                                    label={
                                        <MandatoryLabel
                                            name={"User Name"}
                                            isMandatory={isMandatoryField("userName")}
                                        />
                                    }
                                    type="text"
                                    name="userName"
                                    onBlur={formik.handleBlur}  // Ensure this is included  

                                    value={formik.values.userName}
                                    onChange={formik.handleChange}
                                    labelClass={styles.labelClass}
                                    inputClass={styles.inputClass}
                                    placeholder="Enter User Name"
                                    error={formik.touched.userName && formik.errors.userName}
                                />

                                <div className="flex row">
                                    <InputField
                                        label={
                                            <MandatoryLabel
                                                name={"Password"}
                                                isMandatory={isMandatoryField("password")}
                                            />
                                        }
                                        type={showPassword ? "text" : "password"}
                                        name="password"
                                        onChange={formik.handleChange}

                                        value={formik.values.password}
                                        labelClass={styles.labelClass}
                                        inputClass={styles.inputClass}
                                        placeholder="********"
                                        error={formik.touched.password && formik.errors.password}
                                    />
                                    <button
                                        type="button"
                                        className="absolute right-3 bottom-2 transform -translate-y-1/2 text-gray-500 hover:text-gray-700"
                                        onClick={() => setShowPassword(!showPassword)}
                                    >
                                        {showPassword ? <EyeOpenIcon /> : <EyeClosedIcon />}
                                    </button>
                                </div>

                                <InputField
                                    label={
                                        <MandatoryLabel
                                            name={"Port"}
                                            isMandatory={isMandatoryField("port")}
                                        />
                                    }
                                    type="text"
                                    name="port"
                                    value={formik.values.port}
                                    onChange={formik.handleChange}
                                    labelClass={styles.labelClass}
                                    inputClass={styles.inputClass}
                                    placeholder="Enter Port"
                                    error={formik.touched.port && formik.errors.port}
                                />

                                <InputField
                                    label={
                                        <MandatoryLabel
                                            name={"Table name"}
                                            isMandatory={isMandatoryField("tableName")}
                                        />
                                    }
                                    name="tableName"
                                    value={formik.values.tableName}
                                    onChange={formik.handleChange}
                                    error={formik.touched.tableName && formik.errors.tableName}
                                    placeholder="Table1"
                                    labelClass={styles.labelClass}
                                    inputClass={styles.inputClass}
                                />

                                <InputField
                                    label={
                                        <MandatoryLabel
                                            name={"Column name to retrieve date and time"}
                                            isMandatory={isMandatoryField("dateTimeColumn")}
                                        />
                                    }
                                    // label="Column name to retrieve date and time"
                                    type="text"
                                    name="dateTimeColumn"
                                    value={formik.values.dateTimeColumn}
                                    onChange={formik.handleChange}
                                    error={formik.touched.dateTimeColumn && formik.errors.dateTimeColumn}
                                    labelClass={styles.labelClass}
                                    inputClass={styles.inputClass}
                                    placeholder="Enter Value"
                                />

                                <InputField
                                    label={
                                        <MandatoryLabel
                                            name={"User given name for retrieved date and time"}
                                            isMandatory={isMandatoryField("Username_dateTimeColumn")}
                                        />
                                    }
                                    //label="User given name for retrieved date and time"
                                    type="text"
                                    name="Username_dateTimeColumn"
                                    value={formik.values.Username_dateTimeColumn}
                                    onChange={formik.handleChange}
                                    error={formik.touched.Username_dateTimeColumn && formik.errors.Username_dateTimeColumn}
                                    labelClass={styles.labelClass}
                                    inputClass={styles.inputClass}
                                    placeholder="Enter Value"
                                />

                                <InputField
                                    label={
                                        <MandatoryLabel
                                            name={"Column name to retrieve batch number"}
                                            isMandatory={isMandatoryField("batchNumberColumn")}
                                        />
                                    }
                                    //label="Column name to retrieve batch number"
                                    type="text"
                                    name="batchNumberColumn"
                                    value={formik.values.batchNumberColumn}
                                    onChange={formik.handleChange}
                                    error={formik.touched.batchNumberColumn && formik.errors.batchNumberColumn}
                                    labelClass={styles.labelClass}
                                    inputClass={styles.inputClass}
                                    placeholder="Enter Value"
                                />

                                <InputField
                                    label={
                                        <MandatoryLabel
                                            name={"User given name for retrieved batch number"}
                                            isMandatory={isMandatoryField("Username_batchNumberColumn")}
                                        />
                                    }
                                    //label="User given name for the retrieved batch number"
                                    type="text"
                                    name="Username_batchNumberColumn"
                                    value={formik.values.Username_batchNumberColumn}
                                    onChange={formik.handleChange}
                                    error={formik.touched.Username_batchNumberColumn && formik.errors.Username_batchNumberColumn}
                                    labelClass={styles.labelClass}
                                    inputClass={styles.inputClass}
                                    placeholder="Enter Value"
                                />

                                <InputField
                                    label={
                                        <MandatoryLabel
                                            name={"Column name to retrieve batch description"}
                                            isMandatory={isMandatoryField("jobNumberColumn")}
                                        />
                                    }
                                    type="text"
                                    name="jobNumberColumn"
                                    value={formik.values.jobNumberColumn}
                                    onChange={formik.handleChange}
                                    error={formik.touched.jobNumberColumn && formik.errors.jobNumberColumn}
                                    labelClass={styles.labelClass}
                                    inputClass={styles.inputClass}
                                    placeholder="Enter Value"
                                />

                                <InputField
                                    label={
                                        <MandatoryLabel
                                            name={"User given name for the retrieved batch description"}
                                            isMandatory={isMandatoryField("Username_jobNumberColumn")}
                                        />
                                    }
                                    // label="User given name for the retrieved batch description"
                                    type="text"
                                    name="Username_jobNumberColumn"
                                    value={formik.values.Username_jobNumberColumn}
                                    onChange={formik.handleChange}
                                    error={formik.touched.Username_jobNumberColumn && formik.errors.Username_jobNumberColumn}
                                    labelClass={styles.labelClass}
                                    inputClass={styles.inputClass}
                                    placeholder="Enter Value"
                                />
                                <InputField
                                    label={
                                        <MandatoryLabel
                                            name={"Column name to retrieve MC group column"}
                                            isMandatory={isMandatoryField("MCGroupColumn")}
                                        />
                                    }
                                    type="text"
                                    name="MCGroupColumn"
                                    value={formik.values.MCGroupColumn}
                                    onChange={formik.handleChange}
                                    error={formik.touched.MCGroupColumn && formik.errors.MCGroupColumn}
                                    labelClass={styles.labelClass}
                                    inputClass={styles.inputClass}
                                    placeholder="Enter Value"
                                />

                                <InputField
                                    label={
                                        <MandatoryLabel
                                            name={"User given name for the MC group column"}
                                            isMandatory={isMandatoryField("Username_MCGroupColumn")}
                                        />
                                    }
                                    // label="User given name for the retrieved batch description"
                                    type="text"
                                    name="Username_MCGroupColumn"
                                    value={formik.values.Username_MCGroupColumn}
                                    onChange={formik.handleChange}
                                    error={formik.touched.Username_MCGroupColumn && formik.errors.Username_MCGroupColumn}
                                    labelClass={styles.labelClass}
                                    inputClass={styles.inputClass}
                                    placeholder="Enter Value"
                                />

                            </div>
                        </div>

                        <div className='flex w-full gap-x-4 mt-2 justify-end'>
                            <button type='button' className='w-24 h-10 flex justify-center items-center border border-borderColorBox rounded-md text-customGray text-[12px]/[24px]' onClick={navigateBack}>Cancel</button>
                            <button
                                type="submit"
                                className={`flex justify-center items-center py-2 px-1 h-[40px] bg-baseOrange w-48 text-[12px] text-white"}`}
                                style={{ borderRadius: '6px', color: 'white' }}
                            >
                                Connect to Database
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            {notificationOpen && (
                <Notification
                    open={notificationOpen}
                    message={notificationMessage}
                    severity={notificationSeverity}
                    onClose={handleNotificationClose}
                />
            )}
            {dbNotificationOpen && (
                <DbNotification
                    open={dbNotificationOpen}
                    message={dbNotificationMessage}
                    severity={dbNotificationSeverity}
                    onClose={handleDbNotificationClose}
                    dbDetails={dbDetails} // Pass dbDetails to the notification
                />
            )}
        </>
    );
};

const styles = {
    labelClass: "text-textHeading text-[10px] font-semibold whitespace-nowrap text-ellipsis w-full",
    inputClass: "p-3 h-[44px] border border-borderColorBox rounded-md text-textGray text-[10px]"
}

export default DBConnection;

import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { SuccessIcon, ErrorIcon, WarningIcon } from '../../assets/svgs';
import { PATHS } from '../../routes/path';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
const DbNotification = ({
  open,
  message,
  onClose,
  severity,
  buttonText = "View Details",
  buttonTwo = "true",
  backgroundClass = "",
  handleViewDetails,
  titleText = "",
  masterBatchDates,
  showMasterBatchDates=false,
  deleteFunction = undefined,
  showDelete = false,
}) => {
  const navigate = useNavigate();
  const containerRef = useRef(null)
  // Determine the appropriate icon based on the severity
  const icon = severity === 'success' ? (
    <SuccessIcon className="text-green-600 text-5xl" />
  ) : severity === 'error' ? (
    <ErrorIcon className="text-red-600 text-5xl" />
  ) : (
    <WarningIcon className="text-[#6099E8] text-5xl" />
  );

  // Determine the button color based on the severity
  const buttonColor = severity === 'success' ? 'bg-green-600' :
    severity === 'error' ? 'bg-red-600' :
      'bg-[#6099E8]';  // Default to warning color

  const handleOverlayClick = (e) => {
    if (containerRef.current && !containerRef.current.contains(e.target)) {
      onClose()
      navigate(PATHS.homePath);
    }
  };

  return (
    open && (
      <div className={`fixed inset-0 bg-backgroundColor bg-opacity-70 ${backgroundClass} flex justify-center items-center z-30 `} onClick={handleOverlayClick}>
        <div className="bg-white w-96 min-h-44 rounded-lg p-2 flex flex-col" ref={containerRef}>
          <div className="flex justify-between items-start mb-1">
            <div className="flex items-center">
              {icon}
              <h6 className="ml-2 text-gray-700 text-xl">
                {severity === 'success' ? 'Success' :
                  severity === 'error' ? 'Error' :
                    'Information'}
              </h6>
            </div>
            <button className="text-gray-500 text-3xl mr-3" onClick={onClose}>
              &times;
            </button>
          </div>

          <div className="flex-grow text-[12px]/[20px] text-customGray text-gray-700 px-3 text-sm">
            <p>{showMasterBatchDates?masterBatchDates:message}</p>
          </div>

          <div className="flex justify-end mb-2 items-center gap-3 w-full pl-1">

            {showDelete && <div title={titleText} className='cursor-pointer' onClick={deleteFunction}><DeleteOutlineIcon className='text-red-500' /></div>}
            {buttonTwo && <button
              className="px-4 py-3 min-h-8 text-[14px] text-customGray border border-gray-300 rounded-md hover:bg-gray-100"
              onClick={onClose}
            >
              Cancel
            </button>}

            {(severity === 'success' || severity === 'warning') && (
              <button
                className={`px-4 py-3 min-h-8 text-[14px] w-full text-white rounded-md ${buttonColor} hover:bg-opacity-80`}
                onClick={handleViewDetails}
              >
                {buttonText}
              </button>
            )}
          </div>
        </div>
      </div>
    )
  );
};

export default DbNotification;

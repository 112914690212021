export function safeExtractDate(line, position) {
   
    if (typeof line !== "string") {
      console.warn(`Invalid input for line: Expected a string but got ${typeof line}`, line);
      return null; // Return a fallback value
    }
  
    const dates = line.match(/\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}/g);
    if (!dates || dates.length < position) {
      console.warn(`No date found at position ${position} in line:`, line);
      return null; // Return a fallback value
    }
  
    return new Date(dates[position - 1]); // Position is 1-based
  }
  export function safeExtractDateTesting(line, position) {
    if (typeof line !== "string") {
      console.warn(`Invalid input for line: Expected a string but got ${typeof line}`, line);
      return null; // Return a fallback value
    }
  
    // Regex to match ISO 8601 date-time format
    const matches = line.match(/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z/g);
    if (!matches || matches.length < position) {
      console.warn(`No date-time found at position ${position} in line:`, line);
      return null; // Return a fallback value
    }

    // Parse and format the date
    const dateTime = new Date(matches[position - 1]); // Position is 1-based
    const formattedDate = dateTime.toLocaleDateString("en-GB"); // Format as DD/MM/YYYY
    const formattedTime = dateTime.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      second: undefined,
      hour12: true, // Use 12-hour clock
    });
  
    return `${formattedDate} - ${formattedTime}`;
  }
  function extractStartEndDate(str) {
    const regex = /(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z) - (\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z)/;
    const match = str.match(regex);
    if (match) {
        return {
            startDate: match[1], 
            endDate: match[2]
        };
    } else {
        console.warn("Failed to extract dates from the string");
        return null;
    }
}


  
export function extractReadableDates(obj) {
 
  if (!obj) {
      return null;
  }

  let startDate, endDate;

  if (Array.isArray(obj)) {


      if (obj.length > 0) {
          startDate = extractStartEndDate(obj[0]?.batch_id_with_date, 1);
          endDate = extractStartEndDate(obj[0]?.batch_id_with_date, 2);
      } else {
      }
  } else if (typeof obj === "object") {
      if (obj.idWithDate) {
          startDate = safeExtractDateTesting(obj.idWithDate, 1);
          endDate = safeExtractDateTesting(obj.idWithDate, 2);
      } else {
      }
  } else {
      return null;
  }

  
  if (startDate && endDate) {
      const result = {
          ...obj,
          startDate,
          endDate,
      };
      return result;
  } else {
      return null;
  }
}


  
import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Dialog, DialogContent, DialogActions, Button, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { PATHS } from '../../routes/path';
import { useAxiosPrivate } from '../../hooks/useAxiosPrivate';

const EditPens = ({  onClose, handleDelete, closeModal,titleText="",confirmationText="" }) => {


    return (
        <Dialog
            open={true}
            onClose={closeModal}
            sx={{ '& .MuiPaper-root': { borderRadius: '8px', zIndex: 3000,paddingBottom:0,  } }}
        >
            <IconButton
                onClick={onClose}
                size="small"
                sx={{ position: 'absolute', right: 8, top: 8, color: '#6E7E91', padding: '4px' }}
            >
                <CloseIcon sx={{ fontSize: "18px" }} />
            </IconButton>

            <DialogContent >
                <Typography className='px-4'>
                    <b className='text-[16px] font-semibold'>{titleText}</b>
                    <br />
                    <span style={{ marginTop: '8px', display: 'block', fontSize: "12px" }}>
                        {confirmationText}
                    </span>
                </Typography>
            </DialogContent>

            <DialogActions>
                <Button
                    onClick={onClose}
                    sx={{ color: '#0F172A', border: '1px solid #D9D9D9', borderRadius: '8px', padding: '8px 16px', textTransform: 'none', fontSize: "14px", fontWeight: 'bold', '&:hover': { backgroundColor: '#F5F5F5' } }}
                >
                    Cancel
                </Button>
                <Button
                    onClick={handleDelete}
                    sx={{ backgroundColor: '#F57C00', color: 'white', borderRadius: '8px', padding: '8px 16px', fontSize: "14px", textTransform: 'none', fontWeight: 'bold', '&:hover': { backgroundColor: '#F57C00' } }}
                >
                    Continue
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditPens;

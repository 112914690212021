import React, { useEffect, useRef, useState } from "react";
import { useAxiosPrivate } from "../../hooks/useAxiosPrivate";
import getAPIMap from "../../routes/url/ApiUrls";
import { DeleteIcon, SearchBoxIcon } from "../../assets/svgs/index";
import { useNavigate } from "react-router-dom";
import { PATHS } from "../../routes/path";
import { Close } from "@mui/icons-material";
import { Checkbox } from "@mui/material";

const AddPens = ({
    handleView,
    setSearchParams,
    setShowModal,
    setModalContext,
}) => {
    const [tableList, setTableList] = useState([]);
    const [tableName, setTableName] = useState("");
    const [columnList, setColumnList] = useState([]);
    const [selectedColumns, setSelectedColumns] = useState([]);
    const [originalTableList, setOriginalTableList] = useState([]);
    const [originalColumnList, setOriginalColumnList] = useState([]);
    const [originalPenList, setOriginalPenList] = useState([]);
    const axiosPrivate = useAxiosPrivate();
    const containerRef = useRef(null);
    const [tableSearch, setTableSearch] = useState("");
    const [columnSearch, setColumnSearch] = useState("");
    const [penSearch, setPenSearch] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        const getAllTables = async () => {
            try {
                const response = await axiosPrivate.get(
                    `${getAPIMap("dataBaseTables")}`
                );
                setTableList(response.data.tables);
                setOriginalTableList(response.data.tables);
            } catch (error) {
                console.error("Error fetching tables:", error);
            }
        };
        getAllTables();
    }, []);

    const handleSubmit = async () => {

        try {
            const data = { penlist: selectedColumns };
            const response = await axiosPrivate.post(`${getAPIMap("penlist")}`, data);
            if (response.status === 200) {
                setShowModal(true);
                setModalContext({
                    text: "Pen details added successfully. Check the pen list.",
                    title: "",
                });
            }
        } catch (error) {
            console.error("Error submitting data:", error);
        }
    };

    useEffect(() => {
        const fetchColumnName = async () => {
            try {
                const response = await axiosPrivate.get(
                    `${getAPIMap("dataBaseColumns")}?tableName=${tableName}`
                );

                setColumnList(response.data.coulmns);
                setOriginalColumnList(response.data.coulmns);
            } catch (error) {
                console.error("Error fetching column names:", error);
            }
        };

        if (tableName) {
            fetchColumnName();
        }
    }, [tableName]);

    const navigateBack = () => {
        navigate(PATHS.homePath);
    };
    const handleOverlayClick = (e) => {
        if (containerRef.current && !containerRef.current.contains(e.target)) {
            navigate(PATHS.homePath); 
        }
    };

    const handleCheckboxChange = (column) => {
        const columnObject = {
            pen_name: column, 
            table_name: tableName,
            column_name: column,
        };

        if (selectedColumns.some((item) => item.column_name === column)) {
            const updatedSelectedColumns = selectedColumns.filter(
                (item) => item.column_name !== column
            );
            setSelectedColumns(updatedSelectedColumns);
            setOriginalPenList(updatedSelectedColumns);
        } else {
            setOriginalPenList([...selectedColumns, columnObject]);
            setSelectedColumns([...selectedColumns, columnObject]);
        }
    };

    const handlePenNameChange = (index, newPenName) => {
        const sanitizedPenName = newPenName.replace(/[^a-zA-Z0-9_ ]/g, "");
        const updatedColumns = [...selectedColumns];
        updatedColumns[index] = {
            ...updatedColumns[index],
            pen_name: sanitizedPenName, 
        };
        setSelectedColumns(updatedColumns); 
    };

    const handleSearchTable = (e) => {
        const tableSearchValue = e.target.value;
        setTableSearch(tableSearchValue);

        setTableList(
            tableSearchValue.trim()
                ? originalTableList.filter((table) =>
                    table.toLowerCase().includes(tableSearchValue.toLowerCase())
                )
                : originalTableList
        );
    };

    const handleSearchColumn = (e) => {
        const tableSearchValue = e.target.value;
        setColumnSearch(tableSearchValue);

        setColumnList(
            tableSearchValue.trim()
                ? originalColumnList.filter((table) =>
                    table.toLowerCase().includes(tableSearchValue.toLowerCase())
                )
                : originalColumnList
        );
    };



    
    const handleSearchPen = (e) => {
        const searchValue = e.target.value;
        setPenSearch(searchValue);

        if (searchValue.trim() === "") {
            setSelectedColumns(originalPenList);

            return;
        }

        const filteredPenList = selectedColumns.filter((item) =>
            item.column_name?.toLowerCase().includes(searchValue.toLowerCase())
        );

        setSelectedColumns(filteredPenList);
    };

    return (
        <div
            className="fixed inset-0 flex items-center justify-center bg-opacity-50 bg-black z-[99]"
            onClick={handleOverlayClick}
        >
            <div
                className="w-8/12 p-4 max-h-[90vh] rounded-md bg-white"
                ref={containerRef}
            >
                {/** Header */}
                <div className="flex justify-between items-start mb-1">
                    <h3 className="text-black text-[12px]/[16px] font-semibold">
                        Add Pen
                    </h3>
                    <div onClick={navigateBack}>
                        <Close className="!w-5 !h-5 cursor-pointer" />
                    </div>
                </div>
                {/** Main content */}
                <div className="flex w-full flex-grow h-[60vh] gap-4">
                    <div className="flex w-1/2">
                        <div className="w-1/2 border-r-2 h-full border-borderColorBox">
                            <h1 className="text-customGray text-[12px]/[21px] font-medium">
                                Table Name
                            </h1>
                            <div className="mt-2 relative">
                                <input
                                    type="text"
                                    className="w-full border-t text-[10px]/[18px] h-8 text-[#555555] border-b focus:outline-none border-l-0 border-r-0 pr-10 border-borderColorBox placeholder:text-[12px]/[18px] placeholder:text-[#A9A3A3]"
                                    placeholder="Search table name"
                                    value={tableSearch}
                                    onChange={handleSearchTable}
                                />
                                <div className="absolute right-4 top-1/2 transform -translate-y-1/2">
                                    <SearchBoxIcon />
                                </div>
                            </div>
                            <div className=" pt-2 max-h-[50vh] overflow-y-auto pr-2">
                                {tableList?.map((table) => (
                                    <div
                                        key={table}
                                        className={`text-customGray pl-2 ${tableName === table
                                            ? "bg-textGrayHover rounded-[2px]"
                                            : ""
                                            }  hover:bg-textGrayHover text-[10px]/[18px] py-1 cursor-pointer`}
                                        onClick={() => {
                                            setTableName(table);
                                            setColumnList([]);
                                            setSelectedColumns([]);
                                        }}
                                    >
                                        {table}
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="w-1/2 border-r-2 h-full border-borderColorBox">
                            <h1 className="text-customGray text-[12px]/[21px] pl-4 font-medium">
                                Column Name
                            </h1>
                            <div className="mt-2 relative">
                                <input
                                    type="text"
                                    className="w-full border-t border-b text-[10px]/[18px] h-8 text-[#555555] focus:outline-none border-l-0 border-r-0 pl-4 pr-10 border-borderColorBox placeholder:text-[12px]/[18px] placeholder:text-[#A9A3A3]"
                                    placeholder="Search column name"
                                    value={columnSearch}
                                    onChange={(e) => {
                                        handleSearchColumn(e);
                                    }}
                                />
                                <div className="absolute right-4 top-1/2 transform -translate-y-1/2">
                                    <SearchBoxIcon />
                                </div>
                            </div>

                            {columnList.length === 0 ? (
                                <div className="text-customGray text-center text-xs flex justify-center items-center pt-4 ">
                                    Select a table to get started
                                </div>
                            ) : (
                                <div className="pt-2 max-h-[50vh] overflow-y-auto">
                                    {columnList?.map((column) => (
                                        <div
                                            key={column.columnName}
                                            className={`text-customGray ${column.isPenSet ? "opacity-60" : ""
                                                } border-b border-borderColorBox flex items-center mx-2 pl-2 ${selectedColumns.some(
                                                    (item) => item.column_name === column
                                                )
                                                    ? "bg-textGrayHover"
                                                    : ""
                                                } rounded-md hover:bg-textGrayHover text-[10px]/[18px] py-2 bg-white checked:border-gray-500 checked:icon-chec`}
                                        >
                                            <span className="flex-grow cursor-pointer text-[10px]">
                                                {column.columnName}
                                            </span>

                                            <div

                                                className={`flex items-center justify-center w-[14px] h-[14px] border-2 border-baseOrange cursor-pointer ${column.isPenSet === true ||
                                                    selectedColumns.some((item) => item.column_name === column.columnName)
                                                    ? 'bg-baseOrange'
                                                    : 'bg-white'
                                                    }`}
                                                onClick={() => {
                                                    if (!column.isPenSet) {
                                                        handleCheckboxChange(column.columnName);
                                                    }
                                                }}
                                                role="checkbox"
                                                aria-checked={
                                                    column.isPenSet === true ||
                                                    selectedColumns.some((item) => item.column_name === column.columnName)
                                                }
                                                tabIndex={0}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter' || e.key === ' ') {
                                                        e.preventDefault();
                                                        handleCheckboxChange(column.columnName);
                                                    }
                                                }}
                                            >
                                                {(column.isPenSet === true ||
                                                    selectedColumns.some((item) => item.column_name === column.columnName)) && (
                                                        <svg width="8" height="8" viewBox="0 0 10 10" fill="#eb7e39" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M1 5L4 8L9 1" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg>
                                                    )}
                                            </div>

                                            <input
                                                type="checkbox"
                                                disabled={column.isPenSet === true}
                                                checked={
                                                    column.isPenSet === true ||
                                                    selectedColumns.some((item) => item.column_name === column.columnName)
                                                }
                                                onChange={() => handleCheckboxChange(column.columnName)}
                                                className="hidden"
                                            />
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="w-6/12 flex flex-col h-full">
                        <h1 className="text-customGray text-[12px]/[21px] font-medium">
                            Pen list
                        </h1>
                        <div className=" border rounded-md overflow-y-auto border-borderColorBox flex-grow h-full mt-1">
                            <div
                                className={`border rounded-md border-borderColorBox h-full flex-grow ${selectedColumns.length > 0 ? "overflow-y-auto" : ""
                                    }`}
                            >
                                <div className="mt-1 relative mx-4">
                                    <input
                                        type="text"
                                        className="w-full border-t-0 h-8 text-[10px]/[18px] text-[#555555] focus:outline-none border-b border-l-0 border-r-0 pr-10 pl-2 border-borderColorBox placeholder:text-[12px]/[18px] placeholder:text-[#A9A3A3]"
                                        placeholder="Search pen name"
                                        value={penSearch}
                                        onChange={handleSearchPen}
                                    />
                                    <div className="absolute right-4 top-1/2 transform -translate-y-1/2">
                                        <SearchBoxIcon />
                                    </div>
                                </div>

                                <div className="mt-4 max-h-[60vh] flex flex-col">
                                    {selectedColumns.length > 0 ? (
                                        selectedColumns.map((columnObj, index) => (
                                            <div
                                                key={index}
                                                className="flex mx-4 border-b border-borderColorBox items-center justify-between text-customGray text-[10px]/[18px] py-2 mb-2"
                                            >
                                                <div className="flex-1 rounded-md text-customGray pl-2 h-full py-1 bg-grayDarkBg">
                                                    {columnObj.column_name}
                                                </div>
                                                <input
                                                    type="text"
                                                    className="border border-borderColorBox focus:outline-none rounded-md px-2 py-1 text-[10px] text-customGray flex-1 mx-2 w-11/12"
                                                    placeholder={"Enter user friendly name"}
                                                    value={columnObj.pen_name||""}
                                                    onChange={(e) => handlePenNameChange(index, e.target.value)} 
                                                />


                                                <button
                                                    className="text-red-500 text-[12px] px-2 py-1 hover:underline"
                                                    onClick={() => {
                                                        const updatedColumns = selectedColumns.filter(
                                                            (_, idx) => idx !== index
                                                        );
                                                        setSelectedColumns(updatedColumns);
                                                    }}
                                                >
                                                    <DeleteIcon color={"#979797"} />
                                                </button>
                                            </div>
                                        ))
                                    ) : (
                                        <div className="flex flex-grow justify-center items-center text-center text-customGray text-xs">
                                            No columns selected
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/** Footer */}
                <div className="w-full justify-end">
                    <div className=" mt-4 flex justify-end gap-x-4">
                        <button
                            onClick={navigateBack}
                            type="button"
                            className="px-4 text-[#0F172A] text-semibold text-[12px]/[24px] flex justify-center items-center py-2 border border-borderColorBox rounded-md focus:outline-none"
                        >
                            Cancel
                        </button>
                        <button
                            type="button"
                            onClick={handleSubmit}
                            disabled={
                                selectedColumns.length === 0 ||
                                !selectedColumns.every((item) => item.pen_name)
                            }
                            className={`bg-baseOrange focus:outline-none text-white min-w-32 px-4 flex justify-center items-center py-1 rounded-md text-[12px] font-medium cursor-pointer ${selectedColumns.length === 0 ||
                                !selectedColumns.every((item) => item.pen_name)
                                ? "opacity-50"
                                : ""
                                }`}
                        >
                            Save Penlist
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddPens;

import React, { useState, useEffect } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { DownloadIcon } from '../../assets/svgs';
import { rgbToHex } from '@mui/material';
import { Checkbox } from '@mui/material';
import RestartAltIcon from "@mui/icons-material/RestartAlt";

import CustomSelect from '../CustomSelect';
import { ToggleSidebarIcon } from '../../assets/svgs';
const SidebarMenu = ({
    sidebarOpen,
    setSidebarOpen,
    data,
    handleToggleDataset,
    handleChangeColor,
    handleChangeMinMax,
    handleChangeLineWidth,
    resetZoom,

    handleChangeFormat,
    downloadChart,
    handleChangeYAxisDirection,
    handleToggleLogScale,
    setDownload,
    handleToggleYAxisVisibility
}) => {
    const [expandedIndex, setExpandedIndex] = useState(null);
    const [minValues, setMinValues] = useState(
        data.map(entry => entry.minPredefinedChanged ? entry.minValue : entry.minPredefined)
    );

    const [maxValues, setMaxValues] = useState(
        data.map(entry => entry.maxPredefinedChanged ? entry.maxValue : entry.maxPredefined)
    );

    const handleExpandToggle = (index) => {
        setExpandedIndex(expandedIndex === index ? null : index);
    };
    useEffect(() => {
        setMinValues(
            data.map(entry =>
                entry.minPredefinedChanged
                    ? (entry.minValue !== null  ? entry.minValue : (entry.minPredefined ?? entry.minValue))
                    : (entry.minPredefined !== null && entry.minPredefined !== undefined ? (entry.minPredefined ?? entry.minValue) : entry.minValue)
            )
        );

        setMaxValues(
            data.map(entry =>
                entry.maxPredefinedChanged
                    ? (entry.maxValue !== null  ? entry.maxValue : (entry.maxPredefined ?? entry.maxValue))
                    : (entry.maxPredefined !== null && entry.maxPredefined !== undefined ? (entry.maxPredefined ?? entry.maxValue) : entry.maxValue)
            )
        );

    }, [data])

    const handleNumericChange = (e, index, type) => {
        let value = e.target.value.trim();
    
        if (value !== "" && !/^\d+(\.\d+)?$/.test(value)) {
            return;
        }
    
        if (type === "min") {
            const newMinValues = [...minValues];
            newMinValues[index] = value === "" ? "" : Number(value);
            setMinValues(newMinValues);
            handleChangeMinMax(index, "min", value === "" ? null : Number(value));
        } else if (type === "max") {
            const newMaxValues = [...maxValues];
            newMaxValues[index] = value === "" ? "" : Number(value);
            setMaxValues(newMaxValues);
            handleChangeMinMax(index, "max", value === "" ? null : Number(value));
        }
    };

    const handleBlur = (index) => {
        if (minValues[index] === "" && maxValues[index] === "") {
            handleChangeMinMax(index, "min", (data[index]?.comparisonMin) );
            handleChangeMinMax(index, "max", data[index]?.comparisonMax );
        }
    };

    return (
        <div style={{ flex: '0 0 15%', padding: '10px' }} className="bg-[#F4F4F4] h-full overflow-y-auto mt-4 mr-2 rounded-md">
            <div
                className="flex justify-between px-4 pt-4 gap-x-4 z-20"
                onClick={() => {
                    setSidebarOpen((prev) => !prev);
                }}
            >
                <div className='flex flex-row-reverse gap-x-2 items-center'>
                    <button onClick={resetZoom}>
                        <RestartAltIcon className="w-3 h-3 text-[#737373]" />
                    </button>
                    <div
                        className="flex justify-end pr-4 gap-x-4 z-20"
                        onClick={() => {
                            setSidebarOpen((prev) => !prev);
                        }}
                    >
                        <div onClick={downloadChart} className="cursor-pointer">
                            <DownloadIcon />
                        </div>
                    </div>
                </div>
                <div className={`${sidebarOpen ? "rotate-180" : ""}`}>
                    <ToggleSidebarIcon />
                </div>
            </div>

            <div className='mt-10'>
                {data?.length > 0 ? (
                    data.map((entry, index) => {
                        const isVisible = entry.isVisible || entry.isDefault;
                        return (
                            <div key={entry.penId} style={{ marginBottom: '8px' }} className="border-b w-full border-[#F1F5F9] pb-2">
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} className="w-full border-b border-[#D9DDE0] pb-2">
                                    <label className="flex items-center gap-x-2 bg-[#F4F4F4] w-full">
                                        <div className="flex items-center justify-between w-full">
                                            <div className='flex items-center'>
                                                <div
                                                    style={{
                                                        backgroundColor: entry.fillColor,
                                                        width: '15px',
                                                        height: '15px',
                                                        borderRadius: '50%',
                                                        marginLeft: '10px',
                                                        flexShrink: 0,
                                                    }}
                                                />
                                                <div style={{ marginLeft: '10px', color: '#000' }} className='text-[12px]/[20px]'>
                                                    {entry.penName}
                                                </div>
                                            </div>
                                            <div style={{ display: 'flex', alignItems: 'center', marginLeft: '10px' }}>
                                                <div
                                                    style={{
                                                        width: '30px',
                                                        height: '12px',
                                                        backgroundColor: entry.isVisible ? entry.fillColor : '#d5d5d5', // Filled color when visible
                                                        borderRadius: '6px',
                                                        position: 'relative',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={() => handleToggleDataset(index)}
                                                >
                                                    <div
                                                        style={{
                                                            position: 'absolute',
                                                            top: '0px',
                                                            left: entry.isVisible ? '20px' : '2px', // Left position based on visibility
                                                            width: '12px',
                                                            height: '12px',
                                                            borderRadius: '50%',
                                                            backgroundColor: 'white',
                                                            transition: 'left 0.3s ease',
                                                        }}
                                                    />
                                                </div>

                                                <div onClick={() => handleExpandToggle(index)}
                                                    style={{ cursor: 'pointer', marginLeft: '5px' }}
                                                    className="flex justify-end"
                                                >
                                                    {expandedIndex === index ? (
                                                        <KeyboardArrowDownIcon className="w-5 h-5" />
                                                    ) : (
                                                        <KeyboardArrowRightIcon className="w-5 h-5" />
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                                {expandedIndex === index && (
                                    <div style={{ marginTop: '5px' }} className="w-full">
                                        {/* Min Value Input */}
                                        <div className="flex w-full justify-between mt-2 bg-[#D9DDE066] p-1 rounded-sm ">
                                            <label className="flex items-center text-[10px]/[20px] text-[#555555]">Min:</label>
                                            <input
                                                type="text"
                                                value={minValues[index] ?? ''}
                                                onChange={(e) => handleNumericChange(e, index, 'min')}
                                                onBlur={() => handleBlur(index, 'min')}
                                                className="flex justify-center w-6/12 text-[10px]/[20px] pl-2 items-center bg-[#F0F5EC] focus:outline-none"
                                            />
                                        </div>

                                        {/* Max Value Input */}
                                        <div className="flex w-full justify-between mt-2 bg-[#D9DDE066] p-1 rounded-sm">
                                            <label className="flex items-center text-[10px]/[20px] text-[#555555]">Max:</label>
                                            <input
                                                type="text"
                                                value={maxValues[index] ?? ''}
                                                onChange={(e) => handleNumericChange(e, index, 'max')}
                                                onBlur={() => handleBlur(index, 'max')}
                                                className="flex justify-center w-6/12 text-[10px]/[20px] pl-2 bg-[#F0F5EC] items-center focus:outline-none"
                                            />
                                        </div>

                                        {/* Fill Color Input */}
                                        <div className="flex w-full justify-between mt-2 bg-[#D9DDE066] p-1 rounded-sm">
                                            <label className="flex items-center text-[10px]/[20px] text-[#555555]">Fill Color:</label>
                                            <input
                                                type="color"
                                                value={rgbToHex(entry.fillColor)}
                                                onChange={(e) => handleChangeColor(index, e.target.value)}
                                                className="flex justify-center items-center text-[10px] w-6/12 bg-[#F0F5EC] focus:outline-none"
                                            />
                                        </div>

                                        {/* Line Width Input */}
                                        <div className="flex w-full justify-between mt-2 bg-[#D9DDE066] p-1 rounded-sm">
                                            <label className="flex items-center text-[10px]/[20px] text-[#555555]">Line Size:</label>
                                            <input
                                                type="number"
                                                min="1"
                                                value={entry.lineWidth}
                                                onChange={(e) => handleChangeLineWidth(index, e.target.value)}
                                                className="flex justify-center items-center bg-[#F0F5EC] text-[10px]/[20px] pl-2 w-6/12 focus:outline-none"
                                            />
                                        </div>
                                        <CustomSelect
                                            label={"Format:"}
                                            index={index}
                                            onChange={handleChangeFormat}
                                            value={entry.selectedFormat}
                                            options={[{ label: "Integer", value: "integer" }, { label: "Real", value: "real" }, { label: "Exponential", value: "logarithmic" }]}
                                        />


                                        {/* <div className="flex w-full justify-between mt-2 bg-[#D9DDE066] p-1 rounded-sm">
                                        <label className="flex items-center">Format:</label>
                                        <select
                                            value={entry.selectedFormat}
                                            onChange={(e) => handleChangeFormat(index, e.target.value)}
                                            className="bg-[#F0F5EC] bg-[#F0F5EC] w-6/12 focus:outline-none"
                                        >
                                            <option value="integer">Integer</option>
                                            <option value="real">Real</option>
                                            <option value="logarithmic">Logarithmic</option>
                                        </select>
                                    </div> */}

                                        {/* Log Scale Toggle */}
                                        <div className="flex w-full justify-between mt-2 bg-[#D9DDE066] p-1 rounded-sm">
                                            <label className="flex items-center text-[10px]/[20px] text-[#555555]">Log Scale:</label>
                                            {/* <input
                                                type="checkbox"
                                                checked={entry.logScaleVisible}
                                                disabled={entry.selectedFormat !== "logarithmic"}
                                                onChange={() => handleToggleLogScale(index)}
                                                className="w-5 h-5 bg-[#F0F5EC] pr-2 focus:outline-none"
                                            /> */}
                                            <Checkbox
                                                checked={entry.logScaleVisible}
                                                disabled={entry.selectedFormat !== "logarithmic"}
                                                onChange={() => handleToggleLogScale(index)}
                                                className="w-5 h-5 bg-[#F0F5EC] pr-2 focus:outline-none"
                                                sx={{
                                                    color: '#EB7E39',
                                                    '& .MuiSvgIcon-root': {
                                                        color: '#EB7E39'

                                                    },
                                                }}
                                            />
                                        </div>


                                        <div className="flex w-full justify-between mt-2 bg-[#D9DDE066] p-1 rounded-sm">
                                            <label className="flex items-center text-[10px]/[20px] text-[#555555]">Scale Visible:</label>
                                            {/* <input
                                                type="checkbox"
                                                checked={entry.scaleVisible}
                                                onChange={(e) => handleToggleYAxisVisibility(index, e.target.checked)}
                                                className="w-5 h-5 bg-[#F0F5EC] pr-2 focus:outline-none"
                                            /> */}
                                            <Checkbox
                                                checked={entry.scaleVisible}
                                                onChange={(e) => handleToggleYAxisVisibility(index, e.target.checked)}
                                                className="w-5 h-5 bg-[#F0F5EC] pr-2 focus:outline-none"
                                                sx={{
                                                    color: '#EB7E39',
                                                    '& .MuiSvgIcon-root': {
                                                        color: '#EB7E39'

                                                    },
                                                }}
                                            />
                                        </div>

                                        <CustomSelect
                                            index={index}
                                            label={"Display Area"}
                                            value={entry.displayArea}
                                            onChange={handleChangeYAxisDirection}
                                            options={[{ label: "Left", value: "left" }, { label: "Right", value: "right" }]} />
                                        {/* <div className="flex w-full justify-between mt-2 bg-[#D9DDE066] p-1 rounded-sm">
                                        <label className="flex items-center">Display Area:</label>
                                        <select
                                            value={entry.displayArea || 'left'} onChange={(e) => handleChangeYAxisDirection(e.target.value, index)}
                                            className="bg-[#F0F5EC] w-6/12 focus:outline-none"
                                        >
                                            <option value="left">Left</option>
                                            <option value="right">Right</option>
                                        </select>
                                    </div> */}
                                    </div>
                                )}
                            </div>
                        );
                    })
                ) : (
                    <p>No datasets available.</p>
                )}
            </div>
        </div>
    );
};

export default SidebarMenu;
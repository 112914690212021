const Joi = require("joi");
const envVarsSchema = Joi.object()
    .keys({
        NODE_ENV: Joi.string().valid("production", "development", "demo", "test").required(),
        REACT_APP_SERVER_URL: Joi.string().required().description("backend server url"),
        REACT_APP_BASE_PATH: Joi.string().required().description("base path for the application"),
    })
    .unknown();

const { value: envVars, error } = envVarsSchema.prefs({ errors: { label: "key" } }).validate(process.env);

if (error) {
    throw new Error(`Config validation error: ${error.message}`);
}


export const config = {
    baseName: envVars.REACT_APP_BASE_PATH,
    api: {
        url: envVars.REACT_APP_SERVER_URL,
    },
    database: {
        serverTypeMandatoryConfig: {
            MSAccess: [
                "serverType",
                "path",
                "databaseName",
                "tableName",
                "dateTimeColumn",
                "batchNumberColumn",
                "jobNumberColumn",
                "Username_dateTimeColumn",
                "Username_batchNumberColumn",
                "Username_jobNumberColumn",
                "MCGroupColumn",
                "Username_MCGroupColumn"
            ],
            MSSQL: [
                "serverType",
                "path",
                "MCGroupColumn",
                "Username_MCGroupColumn",
                "databaseName",
                "tableName",
                "dateTimeColumn",
                "batchNumberColumn",
                
                "jobNumberColumn",
                "Username_dateTimeColumn",
                "Username_batchNumberColumn",
                "Username_jobNumberColumn",
                "port",
                "userName",
                "password",
            ],
        },
    },
};

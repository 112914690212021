import React from 'react';

const HoverAndPenListComponent = ({ data, handleToggleDataset, hoveredPoint }) => {
    console.log(hoveredPoint)
    return (
        <div className='flex items-center'>
            <div className={`flex flex-col items-center justify-center ${hoveredPoint ? "w-24" : "hidden"} space-y-1`}>
                {hoveredPoint && (
                    <>
                        <span className="text-[12px] text-center font-medium">
                            Date and Time
                        </span>
                        <span
                            className="w-full text-[12px] leading-[12px] text-center block overflow-hidden text-ellipsis whitespace-nowrap"
                            title={hoveredPoint?.title}
                        >
                            {hoveredPoint?.title}
                        </span>
                    </>
                )}
            </div>

            <div className="flex bg-gray-200 rounded-lg overflow-hidden ml-2">

                {data.map((group, index) => {

                    const matchedValue = hoveredPoint?.body?.find((item) => {
                        const [parameter] = item.split(": ");

                        return parameter === group.penName;
                    });

                    const [parameter, value] = matchedValue
                        ? matchedValue.split(": ")
                        : [null, null];
                    // const minValue = group.minPredefinedChanged ? group.minValue : (group.minPredefined ?? group.minValue);
                    // const maxValue = group.maxPredefinedChanged ? group.maxValue : (group.maxPredefined ?? group.maxValue);

                    // const minCompareValue = group.minPredefinedChanged
                    //     ? (group.minValue !== null && group.minValue !== undefined ? group.minValue : group.minPredefined)
                    //     : (group.minPredefined !== null && group.minPredefined !== undefined ? group.minPredefined : group.minValue);

                    // const maxCompareValue = group.maxPredefinedChanged
                    //     ? (group.maxValue !== null && group.maxValue !== undefined ? group.maxValue : group.maxPredefined)
                    //     : (group.maxPredefined !== null && group.maxPredefined !== undefined ? group.maxPredefined : group.maxValue);

                    // const minBelowRange = group.comparisonMin < minCompareValue && group.comparisonMax >= minCompareValue;

                    // const maxAboveRange = group.comparisonMax > maxCompareValue && group.comparisonMin <= maxCompareValue;

                    // const equalToRange = group.comparisonMin === minCompareValue && group.comparisonMax === maxCompareValue;

                    // const bothSidesOutOfRange = group.comparisonMin < minCompareValue && group.comparisonMax > maxCompareValue;

                    // const completelyBelowRange = group.comparisonMin < minCompareValue && group.comparisonMax < minCompareValue;
                    // const completelyAboveRange = group.comparisonMin > maxCompareValue && group.comparisonMax > maxCompareValue;

                    // const showValue =
                    //     (minBelowRange || maxAboveRange || equalToRange || bothSidesOutOfRange) &&
                    //     !completelyBelowRange &&
                    //     !completelyAboveRange;

                    const numericValue = Number(value);
                    const minValue = Number(group.minPredefinedChanged ? group.minValue : (group.minPredefined ?? group.minValue));
                    const maxValue = Number(group.maxPredefinedChanged ? group.maxValue : (group.maxPredefined ?? group.maxValue));
                    
                    const showValue = numericValue >= minValue && numericValue <= maxValue;
                    return (
                        <div className='flex '>

                            <div
                                key={group.id}
                                style={{
                                    backgroundColor: group.isVisible ? `${group.fillColor}` : null,
                                }}
                                className="text-white text-[12px] !w-24 p-2  cursor-pointer flex-col items-center justify-center"
                                onClick={() => handleToggleDataset(index)}
                            >
<p className='text-center px-2 break-words whitespace-normal'>{group.penName}</p>

                                {value !== undefined && value !== null && (
    <p className="!text-[12px]/[18px] text-center">
        {numericValue >= minValue && numericValue <= maxValue? value : "-"} <br />
    </p>
)}

                            </div>
                        </div>

                    );
                })}
            </div>
        </div>
    );
};

export default HoverAndPenListComponent;

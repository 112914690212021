import React, { useEffect, useState } from 'react';
import DateRangePickerComponent from '../DateSelecter'; // Adjust the import path as needed
import SearchableSelect from '../SearchableSelect'; // Adjust the import path as needed

const BatchFilter = ({
    setData,
    dummyData,
    dateRange,
    batchList,
    colors,
    data,
    groupList,
    batchLoader,
    handleFetchData,
    penColorMap,
    disabled,
    setPenColorMap,
    setDateRange,
    multiSelect,
    groupData,
    setNavbarError,
    setNavbarErrorText,
    setGroupData,
    handleToggleDataset,
    navbarError,
    navbarErrorText,
    tankdropdownMessage,
    visibleDatasets,
    setVisibleDatasets,
    selectedOptions,
    selectedOptions2,
    setSelectedOptions,
    selectedOptions3,
    setSelectedOptions3,
    tankGroups,
    setSelectedOptions2,
}) => {
    const handleOptionChange = (newSelectedOptions) => {
        setSelectedOptions(newSelectedOptions);
    };

    const handleOptionChange2 = (newSelectedOptions) => {
        setSelectedOptions2(newSelectedOptions);
    };
    const handleOptionChange3 = (newSelectedOptions) => {
        if (navbarError || navbarErrorText.length > 0) {
            setNavbarError(false)
            setNavbarErrorText("")
        }

        setSelectedOptions3(newSelectedOptions);
    };


    useEffect(() => {

    }, [data])

    return (
        <div className="px-2 mt-2 bg-bgBatchDetailsFilter">

            <div className="w-full h-14 mx-4  rounded-t-md  pl-2  ml-2 flex gap-x-1 justify-start items-center">
                <DateRangePickerComponent dateRange={dateRange} setDateRange={setDateRange} />
                <div className="max-w-3/12">
                    <SearchableSelect
                        options={tankGroups}
                        showLabel={false}
                        showSearch={true}
                        multiSelect={multiSelect}
                        singleSelect={false}
                        showParameter={false}
                        disabled={disabled}
                        showAddOption={true}
                        placeholderFallback={tankdropdownMessage}
                        placeholder="Select MC group"
                        selectedOptions={selectedOptions3}
                        onChange={handleOptionChange3}
                    />
                </div>
                <div className="max-w-3/12">
                    <SearchableSelect
                        options={batchList}
                        showLabel={false}
                        showSearch={true}
                        batchLoader={batchLoader}
                        singleSelect={false}
                        multiSelect={multiSelect}
                        showAddOption={true}
                        showParameter={false}
                        showDetails={true}
                        placeholder="Select batch from the drop down"
                        selectedOptions={selectedOptions}
                        onChange={handleOptionChange}
                    />
                </div>

                <div className="max-w-3/12">
                    <SearchableSelect
                        options={groupList}
                        showLabel={false}
                        showSearch={true}
                        multiSelect={multiSelect}
                        singleSelect={false}
                        showParameter={true}
                        showAddOption={true}

                        placeholder="Select group from the drop down"
                        selectedOptions={selectedOptions2}
                        onChange={handleOptionChange2}
                    />
                </div>



                <div
                    className="w-3/12 mr-2 text-[14px] rounded-md bg-baseOrange h-8 text-white flex justify-center items-center cursor-pointer"
                    onClick={handleFetchData}
                >
                    Apply Filters
                </div>

            </div>
            {navbarError && <p className='text-red-500 text-xs pl-4 '>{navbarErrorText}</p>}


        </div>

    );
};

export default BatchFilter;

import React, { useEffect, useState, useRef } from 'react';
import NoFiltersScreen from '../components/NoFiltersScreen/index';
import SearchandSelectBatch from '../components/SearchandSelectBatch';
import { useAxiosPrivate } from '../hooks/useAxiosPrivate';
import getAPIMap from '../routes/url/ApiUrls';
import SidebarMenu from '../components/LineChart/Sidebar';
import CustomMultiLineChart from '../components/LineChart/index';
import html2canvas from 'html2canvas';
import DownloadOverlay from '../components/DownloadOverlay/index';
import { generateRandomDarkColor } from '../utils/color.utils.js'
import { DownloadIcon, ToggleSidebarIcon } from '../assets/svgs/index.jsx';
import Sidebar from '../components/BatchDetailsComponents/BatchDetailsSidebar.jsx';
import DownloadModal from '../components/BatchDetailsComponents/BatchDetailsDownload.jsx';
import { CircularProgress } from '@mui/material';
import HoverAndPenListComponent from '../components/BatchDetailsComponents/HoverAndPenListComponent.jsx';
const BatchDetails = () => {
    const axiosPrivate = useAxiosPrivate();
    const chartRef = useRef(null);
    const [hoveredPoint, sethoveredPoint] = useState(null)
    const [data, setData] = useState([]);
    const [batchList, setBatchList] = useState([]);
    const [groupList, setGroupList] = useState([]);
    const [batchLoader, setBatchLoader] = useState(false)
    const [dateRange, setDateRange] = useState([null, null]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [selectedOptions2, setSelectedOptions2] = useState([]);
    const [tankGroups, setTankGroups] = useState([])
    const [selectedOptions3, setSelectedOptions3] = useState([])
    const [groupData, setGroupData] = useState([]);
    const [showTankTextSelected, setShowTankText] = useState(false)
    const [download, setDownload] = useState(false);
    const [graphLoader, setGraphLoader] = useState(false)
    const [showTextLine, setShowTextLine] = useState(null)
    const [navbarError, setNavbarError] = useState(false)
    const [navbarErrorText, setNavbarErrorText] = useState("")
    const [sidebarOpen, setSidebarOpen] = useState(false)
    const [tankdropdownMessage, setTankDropdownMessage] = useState('Select relevant date')
    const [disableTank, setDisabled] = useState(false)

    const fetchBatchList = async () => {
        setBatchLoader(true)
        try {
            if (!dateRange) {
                return console.error("Invalid date range:", dateRange);
            }
            if (dateRange[0] === null || dateRange[1] === null || dateRange[0] === undefined || dateRange[1] === undefined || dateRange[1].includes("Invalid Date") || selectedOptions3.length === 0) {
                return;
            }
            const [startDate, endDate] = dateRange;
            if (startDate === null || endDate === null || startDate === "Invalid Date" || endDate === "Invalid Date") {
                return console.error("Invalid date range:", dateRange);
            }
            setGroupData([]);
            const response = await axiosPrivate.get(`${getAPIMap("listBatches")}?startDateTime=${startDate}&endDateTime=${endDate}&MCGroup=${selectedOptions3[0]}`);
            const batches = response?.data?.data?.map(batch => ({
                label: batch.id,
                value: batch.id,
                details: batch.idWithDate
            })) || [];
            setBatchList(batches);
        } catch (error) {
            console.error("Error fetching batch list:", error);
            setBatchList([]);
            setData([]);
            setBatchLoader(false)
            setGroupData([]);
        } finally {
            setBatchLoader(false)
        }
    };
    const handleDownload = () => {
        setDownload(false)
        downloadChart()
    }
    const resetZoom = () => {
        if (chartRef.current) {
            chartRef.current.resetZoom()
        } else {
            console.error("Can't reset the zoom")
        }

    }
    const downloadChart = async () => {
        const element = document.getElementById('chart-container');
        const startDate = new Date(dateRange[0]);
        const endDate = new Date(dateRange[1]);

        const formattedStartDate = `${startDate.getFullYear()}-${(startDate.getMonth() + 1)
            .toString()
            .padStart(2, "0")}-${startDate.getDate().toString().padStart(2, "0")}`;
        const formattedEndDate = `${endDate.getFullYear()}-${(endDate.getMonth() + 1)
            .toString()
            .padStart(2, "0")}-${endDate.getDate().toString().padStart(2, "0")}`;
        const d = new Date()
        const dateDownload = `${d.getDate()}-${d.getMonth() + 1}-${d.getFullYear()}`
        const timeDownload = `${String(d.getHours()).padStart(2, '0')}:${String(d.getMinutes()).padStart(2, '0')}`;
        const filename = `${selectedOptions[0]}-${dateDownload}-${timeDownload}.jpeg`;

        if (element) {
            const originalWidth = element.offsetWidth;
            const originalHeight = element.offsetHeight;

            const topPadding = 50;

            const originalCanvas = await html2canvas(element, {
                useCORS: true,
                backgroundColor: 'white',
                width: originalWidth,
                height: originalHeight,
                scale: 2,
            });


            const paddedCanvas = document.createElement('canvas');
            paddedCanvas.width = originalCanvas.width;
            paddedCanvas.height = originalCanvas.height + topPadding;

            const ctx = paddedCanvas.getContext('2d');
            ctx.fillStyle = 'white';
            ctx.fillRect(0, 0, paddedCanvas.width, paddedCanvas.height);
            ctx.drawImage(originalCanvas, 0, topPadding);
            const link = document.createElement('a');
            link.href = paddedCanvas.toDataURL('image/jpeg', 1.0);
            link.download = filename;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setDownload(false);
        } else {
            console.error("Element to capture is not available.");
        }
    };

    const fetchGroupList = async () => {
        try {
            const response = await axiosPrivate.get(getAPIMap("groupByUserType"));
            const groups = response?.data?.map(group => ({
                label: group.name,
                value: group.id,
                penIds: group.penList
            })) || [];
            setGroupList(groups);
        } catch (error) {
            console.error("Error fetching group list:", error);
        }
    };

    useEffect(() => {
        if (dateRange[0] === null || dateRange[1] === null) {
            setTankGroups([])
        }
        fetchGroupList();
        fetchBatchList();
    }, [dateRange, selectedOptions3]);
    const fetchBatchDetailsReport = async () => {
        try {
            const element = document.getElementById('chart-container');
            const startDate = new Date(dateRange[0]);
            const endDate = new Date(dateRange[1]);

            const formattedStartDate = `${startDate.getFullYear()}-${(startDate.getMonth() + 1)
                .toString()
                .padStart(2, "0")}-${startDate.getDate().toString().padStart(2, "0")}`;
            const formattedEndDate = `${endDate.getFullYear()}-${(endDate.getMonth() + 1)
                .toString()
                .padStart(2, "0")}-${endDate.getDate().toString().padStart(2, "0")}`;
            const d = new Date()
            const dateDownload = `${d.getDate()}-${d.getMonth() + 1}-${d.getFullYear()}`
            const timeDownload = `${String(d.getHours()).padStart(2, '0')}:${String(d.getMinutes()).padStart(2, '0')}`;
            const filename = `${selectedOptions[0]}-${dateDownload}-${timeDownload}.pdf`;
            const now = new Date()
            const day = String(now.getDate()).padStart(2, '0'); // Add leading zero if needed
            const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
            const year = now.getFullYear();
            const hours = String(now.getHours()).padStart(2, '0');
            const minutes = String(now.getMinutes()).padStart(2, '0');
            const seconds = String(now.getSeconds()).padStart(2, '0');
            const formattedDateTime = `${day}/${month}/${year}, ${hours}:${minutes}:${seconds}`;

            if (element) {
                const originalWidth = element.offsetWidth;
                const originalHeight = element.offsetHeight;

                const topPadding = 50;

                const originalCanvas = await html2canvas(element, {
                    useCORS: true,
                    backgroundColor: 'white',
                    width: originalWidth,
                    height: originalHeight,
                    scale: 2,
                });
                const paddedCanvas = document.createElement('canvas');
                paddedCanvas.width = originalCanvas.width;
                paddedCanvas.height = originalCanvas.height + topPadding;

                const ctx = paddedCanvas.getContext('2d');
                ctx.fillStyle = 'white'; // Set background color
                ctx.fillRect(0, 0, paddedCanvas.width, paddedCanvas.height); // Fill the padded canvas
                ctx.drawImage(originalCanvas, 0, topPadding); // Draw the original canvas with padding

                const dataURL = paddedCanvas.toDataURL('image/jpeg', 1.0);

                const blob = await (await fetch(dataURL)).blob();

                const formData = new FormData();
                formData.append('image', blob, 'Batch details.jpeg');
                const penIds = selectedOptions2.flatMap(groupValue => {
                    const matchingGroup = groupList.find(group => group.value === groupValue);
                    return matchingGroup
                        ? matchingGroup.penIds
                            .filter(pen => pen.pen_id !== null)
                            .map(pen => ({ id: pen.pen_id, format: pen.selectedFormat }))
                        : [];
                });
                const penFormats = data.filter(item => selectedOptions2.includes(item.penId));

                const penDetails = data.map(item => {
                    return {
                        id: item.penId,
                        format: item.selectedFormat === "logarithmic" ? "exponential" : item?.selectedFormat?.toLowerCase() ? item?.selectedFormat?.toLowerCase() : "Integer",
                        min: item?.minPredefinedChanged
                            ? (item?.minValue !== null && item?.minValue !== undefined ? item?.minValue : item?.minPredefined)
                            : (item?.minPredefined !== null && item?.minPredefined !== undefined ? item?.minPredefined : item?.minValue),

                        max: item?.maxPredefinedChanged
                            ? (item?.maxValue !== null && item?.maxValue !== undefined ? item?.maxValue : item?.maxPredefined)
                            : (item?.maxPredefined !== null && item?.maxPredefined !== undefined ? item?.maxPredefined : item?.maxValue)

                    };
                    return null;
                }).filter(item => item !== null);
                penDetails.forEach(({ id, format, min, max }) => {
                    formData.append("penIds[]", JSON.stringify({ id, format, min, max })); // Append the object as a JSON string
                });

                formData.append("startDateTime", dateRange[0]);
                formData.append("endDateTime", dateRange[1]);
                formData.append("groupId", selectedOptions2[0]);
                formData.append("batchNumber", selectedOptions[0]);
                formData.append("downloadDateTime", formattedDateTime);
                // {selectedOptions3.length>0&&selectedOptions3.forEach((data) => {
                //     formData.append("tankGroup[]", JSON.stringify(data)); // Append the object as a JSON string
                // });}
                selectedOptions3.length > 0 && formData.append("MCGroup", selectedOptions3[0]);


                const response = await axiosPrivate.post(getAPIMap("downloadBatchDetails"), formData, {
                    responseType: 'blob' // Important to handle the response as a blob
                });

                if (response.status === 200) {
                    // Create a URL for the PDF blob
                    const pdfBlob = response.data;
                    const pdfURL = window.URL.createObjectURL(pdfBlob);

                    // Create an anchor element to trigger the download
                    const link = document.createElement('a');
                    link.href = pdfURL;
                    link.download = filename; // Set the filename for download
                    document.body.appendChild(link); // Append to DOM (required for some browsers)
                    link.click(); // Programmatically click the link to trigger the download
                    document.body.removeChild(link); // Remove the link after downloading
                } else {
                    console.error('Error uploading chart image:', response.statusText);
                }
            } else {
                console.error("Element to capture is not available.");
            }
        } catch (error) {
            console.error('Error capturing or uploading the chart image:', error);
        }
    };
    const [chartState, setChartState] = useState(null);

    const saveChartState = () => {
        const state = chartRef.current?.getCurrentState(); // Example for a chart library
        setChartState(state);
    };

    const restoreChartState = () => {
        if (chartState) {
            chartRef.current?.setState(chartState); // Example for a chart library
        }
    };

    useEffect(() => {
        restoreChartState();
    }, [sidebarOpen]);

    const handleReportDownload = () => {
        setDownload(false)
        fetchBatchDetailsReport()
    }
    useEffect(() => {

        if (selectedOptions3.length === 0) {

            if (selectedOptions3.length > 0) {
                setSelectedOptions([])
            }
        }
        if (selectedOptions3.length === 0) {
            setShowTankText(false)
        }
    }, [selectedOptions, selectedOptions3])
    useEffect(() => {
        if (!Array.isArray(data)) {
            return;
        }

        const result = data.reduce((acc, {
            minPredefined,
            minValue,
            minPredefinedChanged,
            maxPredefinedChanged,
            maxPredefined,
            maxValue,
            comparisonMin,
            comparisonMax,
            penName,
            isVisible
        }) => {
            if (!isVisible) return acc;

            // Compute the comparison values based on predefined changes
            const minCompareValue = minPredefinedChanged
                ? (minValue !== null && minValue !== undefined ? minValue : minPredefined)
                : (minPredefined !== null && minPredefined !== undefined ? minPredefined : minValue);

            const maxCompareValue = maxPredefinedChanged
                ? (maxValue !== null && maxValue !== undefined ? maxValue : maxPredefined)
                : (maxPredefined !== null && maxPredefined !== undefined ? maxPredefined : maxValue);

            // Case where comparisonMin is less than required but comparisonMax is within range
            const minBelowRange = comparisonMin < minCompareValue && comparisonMax >= minCompareValue;

            // Case where comparisonMax is more than required but comparisonMin is within range
            const maxAboveRange = comparisonMax > maxCompareValue && comparisonMin <= maxCompareValue;

            // Case where both comparisonMin and comparisonMax match minCompareValue and maxCompareValue
            const equalToRange = comparisonMin === minCompareValue && comparisonMax === maxCompareValue;

            // Case where one value is too low & the other is too high
            const bothSidesOutOfRange = comparisonMin < minCompareValue && comparisonMax > maxCompareValue;

            // Standard greater than & less than conditions
            const completelyBelowRange = comparisonMin < minCompareValue && comparisonMax < minCompareValue;
            const completelyAboveRange = comparisonMin > maxCompareValue && comparisonMax > maxCompareValue;

            // Now apply the condition to check if it's within range or out of range
            if (minBelowRange || maxAboveRange || equalToRange || bothSidesOutOfRange) {
                acc.push({ penName, status: false }); // Both values within range
            } else if (completelyBelowRange || completelyAboveRange) {
                acc.push({ penName, status: true }); // Mark as out of range (completely below or above)
            } else {
                acc.push({ penName, status: false }); // Default case (should not be reached)
            }

            return acc;
        }, []);

        // Function to generate the message based on out-of-range items
        function generateOutOfRangeMessage(data) {
            const outOfRangeItems = data.filter(item => item.status).map(item => item.penName);

            if (outOfRangeItems.length === 0) {
                return;
            }

            return `${outOfRangeItems.join(", ")} ${outOfRangeItems.length > 1 ? "are" : "is"} not within the value range of the graph.`;
        }

        // Generate and set the message
        let sentence = generateOutOfRangeMessage(result);
        setShowTextLine(sentence);

    }, [data]);

    useEffect(() => {

        const getTankList = async () => {
            try {

                if (dateRange[0] === null || dateRange[1] === null || dateRange[0] === undefined || dateRange[1] === undefined) {
                    return;
                }
                const [startDate, endDate] = dateRange;
                const data = {
                    startDateTime: dateRange[0],
                    endDateTime: dateRange[1],
                    batchNumber: selectedOptions[0]
                }
                const response = await axiosPrivate.get(`${getAPIMap(`getTankList`)}?startDateTime=${startDate}&endDateTime=${endDate}`, data)
                const responseData = response.data.data
                const tankOptions = responseData.map((data) => {
                    
                    return { label: data.MC_GROUP_COLUMN, value: data.MC_GROUP_COLUMN }
                })
                if (tankOptions.length === 1) {
                    setTankGroups(tankOptions)
                    setSelectedOptions3([tankOptions[0].value]);
                    setDisabled(true)
                } else {
                    setSelectedOptions3([])
                    setTankGroups(tankOptions)
                    setDisabled(false)
                }
                if (responseData.length === 0) {
                    setTankDropdownMessage(response.data.data.message)
                    return;
                }
            } catch (error) {
                console.log(error)
            }
        }

        getTankList()
    }, [dateRange])

    const handleFetchData = async () => {

        try {

            try {
                if (selectedOptions3.length === 0) {
                    setData([])

                    setNavbarError(true);
                    setNavbarErrorText("MC group is required for Batch details");
                    setSidebarOpen(false)
                    setData([])
                    return;
                } else {
                    setNavbarError(false)
                    setNavbarError("")
                }
            } catch (error) {
                console.error("Error in condition check:", error);
            }


            const penId = selectedOptions2.flatMap(groupValue => {
                const matchingGroup = groupList.find(group => group.value === groupValue);
                return matchingGroup
                    ? matchingGroup.penIds.filter(pen => pen.pen_id !== null).map(pen => pen.pen_id)
                    : [];
            });
            // if (selectedOptions3.length === 0) {
            //     setNavbarError(true)
            //     setNavbarErrorText("Tank Group is required for Batch details")
            //     return;
            // }
            const requestBody = {
                penIds: penId,
                groupId: selectedOptions2[0],
                startDateTime: dateRange[0],
                endDateTime: dateRange[1],
                batchNumber: selectedOptions[0],
                ...(selectedOptions3.length > 0 && { MCGroup: selectedOptions3[0] })
            };
            setShowTextLine(null)
            setGraphLoader(true)
            sethoveredPoint(null)
            const response = await axiosPrivate.post(getAPIMap("getBatchDetails"), requestBody);
            generateRandomDarkColor.resetUsedColors();

            const fetchedData = response?.data?.data || [];

            const formattedData = fetchedData.map((pen) => {
                // Convert the keys to lowercase and filter out 'date_time' (case-insensitive comparison)
                const resultKeys = pen.penResult.length > 0
                    ? Object.keys(pen.penResult[0]).filter(key => key.toLowerCase() !== 'date_time')
                    : [];

                const axisLabelKey = resultKeys[0] || null;

                const values = pen.penResult.map(result => {
                    const value = result[axisLabelKey];
                    return Number(value);  // Convert to number
                });


                const numericValues = values.map(value => (isNaN(value) ? 0 : value));

                // Calculate the min and max values for the numeric values
                const minValue = Math.round(Math.min(...numericValues) * 10000) / 10000;
                const maxValue = Math.round(Math.max(...numericValues) * 10000) / 10000;
                if (selectedOptions3.length > 0) {
                    setShowTankText(true)
                }
                return {
                    penId: pen.penId,
                    penName: pen.penName,
                    penResult: pen.penResult,
                    axisLabel: axisLabelKey,
                    minValue: minValue,
                    maxValue: maxValue,
                    comparisonMin: minValue,
                    comparisonMax: maxValue,

                    // selectedFormat: "Integer",  // Assuming this is a static value
                    fillColor: generateRandomDarkColor.getRandomColor(),
                    lineWidth: 1,
                    displayArea: "left",
                };
            });



            const updatedFormattedData = formattedData.map(pen => {
                const matchingGroupDetail = response?.data?.group?.groupPenDetails?.find(detail => detail.pen_id === pen.penId);
                return {
                    ...pen,
                    isVisible: matchingGroupDetail ? matchingGroupDetail.isDefault : false,
                    scaleVisible: matchingGroupDetail ? matchingGroupDetail.isDefault : false,
                    minPredefined: matchingGroupDetail ? matchingGroupDetail.min : null,
                    maxPredefined: matchingGroupDetail ? matchingGroupDetail.max : null,
                    minPredefinedChanged: false,
                    maxPredefinedChanged: false,
                    selectedFormat: matchingGroupDetail && matchingGroupDetail.format ? matchingGroupDetail.format : "integer",
                    logScaleVisible: matchingGroupDetail ? matchingGroupDetail.format === "exponential" ? true : false : false,

                };
            });
            const penListModified = groupList.filter((group) => group.value === selectedOptions2[0]);
            setData(updatedFormattedData);
            setGroupData(penListModified[0]?.penIds || []);
        } catch (error) {
            console.error("Error fetching data:", error);
            setData([]);
            setGraphLoader(false)
            if (error?.response?.data?.error) alert(error?.response?.data?.error);
        } finally {
            setGraphLoader(false)
        }
    };


    const handleChangeColor = (index, color) => {
        setData((prevData) =>
            prevData.map((item, idx) =>
                idx === index ? { ...item, fillColor: color } : item
            )
        );
    };

    const handleToggleDataset = (index) => {

        setData((prevData) => {
            return prevData.map((item, idx) =>
                idx === index
                    ? { ...item, isVisible: !item.isVisible, scaleVisible: false }
                    : item
            )
        }
        );
    };
    //     useEffect(()=>{
    // if(data){
    //     if(selectedOptions3.length===0){
    //         setData([])
    //     }
    // }
    //     },[data,selectedOptions3])

    const handleChangeMinMax = (index, type, value) => {
        const parsedValue = parseFloat(value);
        if (isNaN(parsedValue)) {
            return; // Ignore if value is not a number
        }
        setData((prevData) =>
            prevData.map((item, idx) =>
                idx === index
                    ? {
                        ...item,
                        [type === "min" ? "minValue" : "maxValue"]: parsedValue,
                        [type === "min" ? "minPredefinedChanged" : "maxPredefinedChanged"]: true
                    }
                    : item
            )
        );
    };

    const handleChangeLineWidth = (index, value) => {
        let parsedValue = parseInt(value, 10);
        if (isNaN(parsedValue)) {
            // return; // Ignore if value is not a number
            parsedValue = 1;
        }

        setData((prevData) =>
            prevData.map((item, idx) =>
                idx === index ? { ...item, lineWidth: parsedValue } : item
            )
        );
    };

    const handleChangeYAxisDirection = (newDirection, index) => {
        setData((prevData) =>
            prevData.map((item, idx) =>
                idx === index ? { ...item, displayArea: newDirection } : item
            )
        );
    };

    const handleChangeFormat = (format, index) => {
        setData((prevData) =>
            prevData.map((item, idx) => {
                if (idx === index) {
                    return {
                        ...item,
                        selectedFormat: format === "logarithmic" || format === "exponential" ? "exponential" : format,
                        logScaleVisible: format === 'logarithmic' || format === 'exponential',
                    };
                }
                return item;
            })
        );
    };

    const handleToggleLogScale = (index, isChecked) => {
        // setData((prevData) =>
        //     prevData.map((item, idx) =>
        //         idx === index
        //             ? { ...item, logScaleVisible: isChecked }
        //             : item
        //     )
        // );
    };


    const [visibleAxisOrder, setVisibleAxisOrder] = useState([]);

    const handleToggleYAxisVisibility = (index, isVisible) => {
        setData((prevData) => {
            let updatedData = prevData.map((item, idx) => {
                if (idx === index) {
                    return { ...item, scaleVisible: isVisible };
                }
                return item;
            });
    
            let updatedOrder = [...visibleAxisOrder];
            const axisLabel = prevData[index].axisLabel;
    
            if (isVisible) {
                updatedOrder.push(axisLabel); // Add newly activated axis
            } else {
                updatedOrder = updatedOrder.filter(label => label !== axisLabel); // Remove deactivated axis
            }
    
            // 🔹 Step 1: Enforce max 3 visible Y-Axes
            let visibleAxes = updatedData.filter(item => item.scaleVisible);
            if (visibleAxes.length > 3) {
                // Hide the oldest one **excluding the one just selected**
                const oldestAxisLabel = updatedOrder.shift();
                if (oldestAxisLabel !== axisLabel) {
                    updatedData = updatedData.map(item =>
                        item.axisLabel === oldestAxisLabel ? { ...item, scaleVisible: false } : item
                    );
                }
            }
    
            // 🔹 Step 2: Ensure exactly 2 Y-Axes are on the Left (if at least 2 exist)
            let leftYAxis = updatedData.filter(item => item.scaleVisible && item.displayArea === "left");
            let rightYAxis = updatedData.filter(item => item.scaleVisible && item.displayArea === "right");
    
            if (visibleAxes.length >= 2) {
                if (leftYAxis.length < 2) {
                    // Move a right-axis to the left to maintain balance
                    const firstRightAxis = rightYAxis[0];
                    if (firstRightAxis) {
                        updatedData = updatedData.map(item =>
                            item.axisLabel === firstRightAxis.axisLabel ? { ...item, displayArea: "left" } : item
                        );
                    }
                } else if (leftYAxis.length > 2) {
                    // Remove the oldest left-axis if more than 2 exist
                    const oldestLeftAxisLabel = leftYAxis[0].axisLabel;
                    updatedData = updatedData.map(item =>
                        item.axisLabel === oldestLeftAxisLabel ? { ...item, scaleVisible: false } : item
                    );
                    updatedOrder = updatedOrder.filter(label => label !== oldestLeftAxisLabel);
                }
            }
    
            // 🔹 Step 3: Ensure at least 1 Y-Axis is on the Right
            rightYAxis = updatedData.filter(item => item.scaleVisible && item.displayArea === "right");
            if (rightYAxis.length === 0) {
                const firstLeftAxis = updatedData.find(item => item.scaleVisible);
                if (firstLeftAxis) {
                    updatedData = updatedData.map(item =>
                        item.axisLabel === firstLeftAxis.axisLabel ? { ...item, displayArea: "right" } : item
                    );
                }
            }
    
            setVisibleAxisOrder(updatedOrder);
            return updatedData;
        });
    };
    


    return (
        <div className="bg-white w-full rounded-r-md rounded-b-md rounded-l-none relative">
            <div >

                <div className={`w-full`}>
                    <div
                        className={`  flex-col  items-center`}
                    >
                        <div className=' pt-1 px-4  w-full' >
                            <SearchandSelectBatch
                                dateRange={dateRange}
                                groupList={groupList}
                                batchLoader={batchLoader}
                                setDateRange={setDateRange}
                                batchList={batchList}
                                groupData={groupData}
                                setGroupData={setGroupData}
                                handleToggleDataset={handleToggleDataset}
                                setData={setData}
                                data={data}
                                disabled={disableTank}
                                handleFetchData={handleFetchData}
                                tankGroups={tankGroups}
                                selectedOptions3={selectedOptions3}
                                setSelectedOptions3={setSelectedOptions3}
                                selectedOptions={selectedOptions}
                                selectedOptions2={selectedOptions2}
                                navbarError={navbarError}
                                setNavbarError={setNavbarError}
                                setNavbarErrorText={setNavbarErrorText}
                                navbarErrorText={navbarErrorText}
                                tankdropdownMessage={tankdropdownMessage}
                                setSelectedOptions={setSelectedOptions}
                                setSelectedOptions2={setSelectedOptions2}
                            />

                        </div>
                    </div>
                    <div id='chart-container'>

                        {data.length > 0 && <div className="flex gap-x-2 pt-2 !z-20 pl-2 bg-bgBatchDetailsFilter mx-4  rounded-b-md pl-6 pb-4">
                            {data.length > 0 ? (
                                <HoverAndPenListComponent handleToggleDataset={handleToggleDataset} hoveredPoint={hoveredPoint} data={data} />
                            ) : null}
                        </div>}

                        <div className="flex flex-row w-full h-full ">
                            {graphLoader ? <div className='h-[65vh] flex justify-center items-center w-full bg-gray-50 bg-opacity-10'> <CircularProgress
                                size={40}
                                sx={{
                                    color: "#EB7E39",
                                }}

                            /></div> : data.length === 0 ? (
                                <div className='h-[72vh] w-full flex justify-center items-center'> <NoFiltersScreen label={'Add filters to generate graph!'} /></div>
                            ) : <div className="flex flex-col w-full h-full p-4 pb-10">
                                <div className="flex w-full justify-between items-center gap-x-4">
                                    <div className='flex flex-col'>
                                        <h2 className="text-[14px]/[18px] font-semibold text-textBoldColor pl-4">Batch Details</h2>
                                        {showTextLine && <p className='flex justify-start text-red-500 pl-4 text-[12px]/[12px] w-full'>{showTextLine}</p>}
                                    </div>
                                    {!sidebarOpen && <div
                                        className="flex justify-end pr-4 gap-x-4 z-20"
                                        onClick={() => {
                                            setSidebarOpen((prev) => !prev);
                                        }}
                                    >

                                        <div className={`${sidebarOpen ? "rotate-180" : ""}`}>
                                            <ToggleSidebarIcon />
                                        </div>
                                    </div>}
                                </div>


                                <div className="flex flex-col mt-4">

                                    <div
                                        className="chart-container mx-auto pr-8 border border-gray-100 rounded-md mb-4"
                                        style={{
                                            width: "100%",
                                            height: "400px",
                                            overflow: "hidden",
                                        }}
                                    >
                                        <CustomMultiLineChart data={data} sethoveredPoint={sethoveredPoint} ref={chartRef} />

                                    </div>
                                </div>
                            </div>

                            }
                        </div>
                    </div>
                </div>
                {/* {sidebarOpen && <div className="sidebar-container absolute right-0  top-28 mt-6 w-[22%] h-[70vh] !z-40">

                    <SidebarMenu
                        data={data}
                        sidebarOpen={sidebarOpen}
                        setSidebarOpen={setSidebarOpen}
                        handleToggleDataset={handleToggleDataset}
                        handleToggleLogScale={handleToggleLogScale}
                        downloadChart={() => setDownload(true)}
                        handleChangeColor={handleChangeColor}
                        handleChangeMinMax={handleChangeMinMax}
                        handleChangeLineWidth={handleChangeLineWidth}
                        handleToggleYAxisVisibility={handleToggleYAxisVisibility}
                        handleChangeFormat={handleChangeFormat}
                        handleChangeYAxisDirection={handleChangeYAxisDirection}
                    />
                </div>}
                 */}
                {data.length > 0 && sidebarOpen && (
                    <Sidebar
                        sidebarOpen={sidebarOpen}
                        setSidebarOpen={setSidebarOpen}
                        data={data}
                        handleToggleDataset={handleToggleDataset}
                        handleToggleLogScale={handleToggleLogScale}
                        downloadChart={() => setDownload(true)}
                        handleChangeColor={handleChangeColor}
                        handleChangeMinMax={handleChangeMinMax}
                        handleChangeLineWidth={handleChangeLineWidth}
                        resetZoom={resetZoom}
                        handleToggleYAxisVisibility={handleToggleYAxisVisibility}
                        handleChangeFormat={handleChangeFormat}
                        handleChangeYAxisDirection={handleChangeYAxisDirection}
                    />
                )}

                {download && (
                    <DownloadOverlay
                        downloadChart={handleDownload}
                        showPdf={true}
                        handleCancel={() => {
                            setDownload(false);
                        }}
                        handleReportDownload={handleReportDownload}
                    />
                )}
            </div>
        </div >

    );
};

export default React.memo(BatchDetails);
import SidebarMenu from "../LineChart/Sidebar";

function Sidebar({ sidebarOpen, setSidebarOpen, ...props }) {
    return (
        <div className="sidebar-container absolute right-0 top-32 mt-6 w-[22%] h-[50vh] !z-40">
            {props.data.length>0&&<SidebarMenu
                sidebarOpen={sidebarOpen}
                setSidebarOpen={setSidebarOpen}
                {...props}
            />}
        </div>
    );
}


export default Sidebar
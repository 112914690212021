
var APIMapping = {
  loginURL: "/auth/login",
  logout: '/auth/logout',
  getAccessToken: `/auth/refresh-tokens`,
  getCustomerDetails: `/auth/name/logo`,
  addUser: `/users`,
  getUserByRole: `/users/role`,
  updateUserById: `/users/:id`,
  getUserById: `/users/:id`,
  getRoles: `/roles`,
  deleteUser: `/users/:id`,
  addUGroup: `/groups`,
  refreshToken: `/auth/refresh-tokens`,
  getGroups: `/groups`,
  getGroupType: `groups/list-group-type`,
  updateGroupById: `/groups/:id`,
  getGroupById: `/groups/:id`,
  deleteGroup: `/groups/:id`,
  logout: `/auth/logout`,
  databaseBase: `/database`,
  getMasterBatch: `/masterBatch/get`,
  setMasterBatch: `masterBatch/set`,
  getBatchDetails: `/database/getbatchdetails`,
  dataBaseTables: `/database/dblisttables`,
  dataBaseColumns: `/database/dblistcolumns`,
  databaseConnect: `/database/dbconnect`,
  getTankList:`/database/listmcgroups`,
  sideBarMenuApi: `/roles/menu`,
  mastBatchPath: `/masterBatch/get`,
  deleteMasterBatch:`/masterBatch/delete`,
  downloadBatchDetails:`/reports/batchDetails`,
  downloadBatchReport:`/reports/deviationReport`,
  listBatches: `/database/listbatches`,
  storeDetails: `/database`,
  batchComparison: `/database/getbatchcomparisondetails`,
  getDeviationGraphDetails: `/database/getdeviationgraphdetails`,
  groupByUserType: `/groups/list-by-user-type`,
  penlist: `/penlist`,
  groupPenDetail: `/groupPenDetail/:id`,
  updateGroupPenDetail: `/groupPenDetail/:id`,
  exportConfig:`/database/export`,
  importConfig:`/database/import`,
  getAllRoles: `/roles`,
};

function getAPIMap(name) {
  return APIMapping[name];
}

export default getAPIMap;

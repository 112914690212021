import React, { useEffect, useRef, useState } from 'react'
import getAPIMap from '../../routes/url/ApiUrls'
import { useAxiosPrivate } from '../../hooks/useAxiosPrivate'
import { useNavigate } from 'react-router-dom'
import { PATHS } from '../../routes/path'
const EditPens = ({ penId, setSearchParams, setShowModal, setModalContext }) => {
    const axiosPrivate = useAxiosPrivate()
    const [prevName, setPrevName] = useState("")
    const [newName, setNewName] = useState("")
    const [pendId, setPenId] = useState(null)
    const [penError, setPenError] = useState("")
    const containerRef = useRef(null)
    const[data,setData]=useState(null)
    const navigate = useNavigate()
    useEffect(() => {
        const getPenById = async () => {
            const response = await axiosPrivate.get(`${getAPIMap("penlist")}/${penId}`)
            setPrevName(response.data.pen_name)
            setData(response.data)
        }
        getPenById()
    }, [])
    const closeModal = () => {
        setSearchParams({ action: "view" })
    }
    const handleSubmit = async () => {
        try {
            const data = { "pen_name": newName }
            const response = await axiosPrivate.put(`${getAPIMap("penlist")}/${penId}`, data)
            if (response.status === 200) {
                setShowModal(true)
                setModalContext({
                    text: "Pen details edited successfully. Check the pen list.",
                    title: "",
                })
            }
        } catch (error) {
            setPenError(error.response.data.message)
  
        }
    }




    
    const handleOverlayClick = (e) => {
        if (containerRef.current && !containerRef.current.contains(e.target)) {
            navigate(PATHS.homePath); 
        }
    };



    return (
        <div  
        className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-[99]"  
        onClick={handleOverlayClick}  
    >  
        <div className='w-full h-screen flex justify-center items-center'>  
            <div className='w-full max-w-md bg-white h-auto rounded-md shadow-lg' ref={containerRef}>  
                <div className='flex justify-between items-center px-4 pt-6'>  
                    <h3 className='text-textHeading font-semibold text-[12px]'>Edit Pen Name</h3>  
                    <button onClick={closeModal} className="text-gray-600 hover:text-gray-800 text-2xl">  
                        &times;  
                    </button>  
                </div>  
                <div className='text-[10px] px-4 text-textGray mb-4'>  
                    Choose a unique name for the pen.  
                </div>  
                <div className='px-4 mt-2 pb-2 text-[12px] text-textHeading font-semibold'>Original Table & Column Name: <span className='text-textGray font-normal text-[10px]/[14px] pl-2'>{data?.table_name} - {data?.column_name}</span></div>                 
                <div className="px-4">  
                    <label className='text-[12px] text-textHeading font-semibold'>User given name<span className='text-red-600'>*</span></label>  
                    <input  
                        type="text"  
                        value={prevName}  
                        onChange={(e) => setNewName(e.target.value)}  
                        className="w-full text-[10px] mt-2 px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"  
                        placeholder="Enter new pen name"  
                        disabled={true}  
                    />  
                </div>  
                <div className="px-4 pt-4">  
                    <label className='text-[12px] text-textHeading font-semibold'>  
                        Enter new name<span className='text-red-600'>*</span>  
                    </label>  
                    <input  
                        type="text"  
                        value={newName}  
                        onChange={(e) => {  
                            const value = e.target.value;  
                            setPenError("");  
                            const filteredValue = value.replace(/[^a-zA-Z0-9_ ]/g, '');  
                            if (filteredValue.length <= 75) {  
                                setNewName(filteredValue);  
                            }  
                        }}  
                        className="w-full text-[10px] mt-2 px-3 py-2 border rounded-md focus:outline-baseOrange"  
                        placeholder="Enter a user friendly name"  
                    />  
                </div>  
                {penError && <div className='text-red-500 text-xs pl-4'>{penError}</div>}  
                <div className="flex justify-end space-x-2 p-4 text-[14px]">  
                    <button className="px-4 py-2 h-10 border border-borderColorBox bg-white text-[12px] text-[#0F172A] hover:outline-none rounded-md" onClick={closeModal}>  
                        Cancel  
                    </button>  
                    <button  
                        className={`px-4 py-2 h-10 bg-baseOrange text-white rounded-md text-[12px] font-medium transition duration-300 ${!newName ? 'bg-gray-400 cursor-not-allowed' : ''}`}  
                        onClick={handleSubmit}  
                        disabled={!newName}  
                    >  
                        Save Changes  
                    </button>  
                </div>  
            </div>  
        </div>  
    </div>
    )
}

export default EditPens

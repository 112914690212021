import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import InputField from "../../../components/InputField";
import Select from "../../../components/SelectBox";
import { useNavigate, useLocation } from "react-router-dom";
import { useAxiosPrivate } from "../../../hooks/useAxiosPrivate";
import getApiMap from "../../../routes/url/ApiUrls";
import Notification from "../../../components/Notifications/index";
import { EyeClosedIcon, EyeOpenIcon } from "../../../assets/svgs";
import MandatoryLabel from "../../../components/common/MandatoryLabel";
import { config } from "../../../assets/config/config";
import { databaseOptions } from "../db-mgmt.constants";

const { serverTypeMandatoryConfig } = config.database;

const EditDBConnection = ({ dbConnectionData }) => {
    const axiosPrivate = useAxiosPrivate();
    const [tableOptions, setTableOptions] = useState([]);
    const [message, setMessage] = useState("");
    const [valuesSet, setValuesSet] = useState(false); // New state to track if values are set
    const [notificationOpen, setNotificationOpen] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState("");
    const [notificationSeverity, setNotificationSeverity] = useState("success");
    const navigate = useNavigate();
    const [formVisible, setFormVisible] = useState(true); // State to control form visibility
    const [showPassword, setShowPassword] = useState(false);
    const [isPasswordValidationVisible, setPasswordValidationVisible] =
        useState(false);

    const validationSchema = Yup.object({
        serverType: Yup.string()
            .required("Server type is required")
            .min(1, "Server type is required"),
        path: Yup.string().min(1, "Path is required"),

        databaseName: Yup.string()
            .required("Database name is required")
            .min(1, "Database name is required"),
        userName: Yup.string().min(
            2,
            "Username must be at least 3 characters long"
        ),
        password: Yup.string().min(
            6,
            "Password must be at least 6 characters long"
        ),
        port: Yup.string().matches(/^[0-9]+$/, "Port must be a valid number"),
        tableName: Yup.string()
            .required("Table name is required")
            .min(1, "Table name is required"),
        dateTimeColumn: Yup.string()
            .required("DateTime column name is required")
            .min(1, "DateTime column name is required"),
        batchNumberColumn: Yup.string()
            .required("Batch number column name is required")
            .min(1, "Batch number column name is required"),
        jobNumberColumn: Yup.string()
            .required("Job number column name is required")
            .min(1, "Job number column name is required"),
        Username_dateTimeColumn: Yup.string()
            .required("User name for DateTime column is required")
            .min(1, "User name for DateTime column is required"),
        Username_batchNumberColumn: Yup.string()
            .required("User name for Batch number column is required")
            .min(1, "User name for Batch number column is required"),
        Username_jobNumberColumn: Yup.string()
            .required("User name for Job number column is required")
            .min(1, "User name for Job number column is required"),
    });

    const handleNotificationClose = () => {
        setNotificationOpen(false);
        setNotificationMessage("");
    };

    const getDataBaseTables = async () => {
        try {
            const response = await axiosPrivate.get(`${getApiMap("dataBaseTables")}`);
            const tables = response.data.tables.map((table) => ({
                label: table,
                value: table,
            }));
            setTableOptions(tables);
        } catch (error) {
            console.error("Error fetching table options:", error);
        }
    };

    // Fetch table options on component mount
    useEffect(() => {
        getDataBaseTables();
    }, [axiosPrivate]);


    const formik = useFormik({
        initialValues: {
            serverType: "",
            path: "",
            databaseName: "",
            userName: "",
            password: "",
            port: "",
            tableName: "",
            dateTimeColumn: "",
            batchNumberColumn: "",
            jobNumberColumn: "",
            Username_dateTimeColumn: "",
            Username_batchNumberColumn: "",
            Username_jobNumberColumn: "",
        },
        validationSchema,
        enableReinitialize: true, // Allow the form to reinitialize when initialValues change
        onSubmit: async (values) => {
            try {
                // Create a new object to filter out non-mandatory fields if they are empty
                const filteredValues = Object.fromEntries(
                    Object.entries(values).filter(
                        ([_, value]) =>
                            value !== undefined && value !== null && value !== ""
                    )
                );

                const response = await axiosPrivate.put(
                    `${getApiMap("databaseBase")}`,
                    filteredValues
                );
                if (response.status === 200) {
                    setNotificationOpen(true);
                    setNotificationMessage("Database updated successfully.");
                    setNotificationSeverity("success");
                    setFormVisible(false); // Close the form after successful submission
                }
            } catch (error) {
                console.error("Error updating database connection:", error);
                setNotificationOpen(true);
                setNotificationMessage(
                    "Failed to update database connection. Please try again."
                );
                setNotificationSeverity("error");
                setMessage("Failed to update database connection. Please try again.");
            }
        },
    });

    const navigateBack = () => {
        navigate("/");
    };

    useEffect(() => {
        if (dbConnectionData) {
            formik.setValues({
                serverType: dbConnectionData.message.serverType || "",
                path: dbConnectionData.message.path || "",
                databaseName: dbConnectionData.message.databaseName || "",
                userName: dbConnectionData.message.userName || "",
                password: dbConnectionData.message.password || "",
                port: dbConnectionData.message.port || "",
                tableName: dbConnectionData.message.tableName || "",
                dateTimeColumn: dbConnectionData.message.dateTimeColumn || "",
                batchNumberColumn: dbConnectionData.message.batchNumberColumn || "",
                jobNumberColumn: dbConnectionData.message.jobNumberColumn || "",
                Username_dateTimeColumn:
                    dbConnectionData.message.Username_dateTimeColumn || "",
                Username_batchNumberColumn:
                    dbConnectionData.message.Username_batchNumberColumn || "",
                Username_jobNumberColumn:
                    dbConnectionData.message.Username_jobNumberColumn || "",
            });
        }
    }, [dbConnectionData]);

    const isMandatoryField = (field) => {
        return serverTypeMandatoryConfig?.[formik.values?.serverType]?.includes(
            field
        );
    };

    return (
        <>
            {formVisible && (
                <div className="fixed inset-0 w-full h-screen flex items-center bg-opacity-80 bg-backgroundColor z-20 justify-center backdrop-blur-[5px]">
                    <div className="w-7/12 bg-white p-8 rounded-md shadow-lg mt-16 overflow-y-auto max-h-[90vh] scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-200">
                        <div className="flex items-center relative">
                            <button
                                onClick={() => {
                                    formik.resetForm();
                                    navigateBack();
                                }}
                                className="absolute top-0 right-0 text-gray-600 hover:text-gray-800 text-2xl"
                            >
                                &times;
                            </button>
                            <div className="text-textHeading2 pt-2 text-[24px]/[28px] font-bold">
                                Edit Details
                            </div>
                        </div>
                        <div className="text-textGray text-[16px]/[20px] pt-1">
                            Edit the required details and save
                        </div>

                        <form className="w-full mt-4" onSubmit={formik.handleSubmit}>
                            <div className="w-full gap-x-4">
                                {/* first box */}
                                <div className="w-full grid grid-cols-2 gap-x-4">
                                    <InputField
                                        // label="Path"
                                        label={
                                            <MandatoryLabel
                                                name={"Path"}
                                                isMandatory={isMandatoryField("path")}
                                            />
                                        } type="text"
                                        name="path"
                                        value={formik.values.path}
                                        onChange={formik.handleChange}
                                        labelClass="text-textHeading text-[14px]/[20px] font-semibold"
                                        inputClass="p-3 h-[44px] border border-borderColorBox rounded-md text-textGray text-[12px]/[24px]"
                                        placeholder="Enter Path"
                                    />
                                    <InputField
                                        // label="Database Name"
                                        label={
                                            <MandatoryLabel
                                                name={"Database Name"}
                                                isMandatory={isMandatoryField("databaseName")}
                                            />
                                        }
                                        type="text"
                                        name="databaseName"
                                        value={formik.values.databaseName}
                                        onChange={formik.handleChange}
                                        labelClass="text-textHeading text-[14px]/[20px] font-semibold"
                                        inputClass="p-3 h-[44px] border border-borderColorBox rounded-md text-textGray text-[12px]/[24px]"
                                        placeholder="Enter Database Name"
                                    />
                                    <Select
                                        // label="Database"
                                        label={
                                            <MandatoryLabel
                                                name={"Database"}
                                                isMandatory={isMandatoryField("serverType")}
                                            />
                                        }
                                        name="serverType"
                                        options={databaseOptions}
                                        value={formik.values.serverType}
                                        onChange={(e) => {
                                            const selectedValue = e.target.value;
                                            formik.setFieldValue("serverType", selectedValue);
                                        }}
                                        error={
                                            formik.touched.serverType && formik.errors.serverType
                                        }
                                        placeholder="Choose database server"
                                        labelClass="text-textHeading text-[14px]/[20px] font-semibold"
                                        inputClass="p-3 h-[44px] border border-borderColorBox rounded-md text-textGray text-[12px]/[24px]"
                                    />
                                </div>
                                {/* Second box */}
                                <div className="w-full grid grid-cols-2 gap-x-4">
                                    <InputField
                                        // label="User Name"
                                        label={
                                            <MandatoryLabel
                                                name={"User Name"}
                                                isMandatory={isMandatoryField("userName")}
                                            />
                                        }
                                        type="text"
                                        name="userName"
                                        value={formik.values.userName}
                                        onChange={formik.handleChange}
                                        labelClass="text-textHeading text-[14px]/[20px] font-semibold"
                                        inputClass="p-3 h-[44px] border border-borderColorBox rounded-md text-textGray text-[12px]/[24px]"
                                        placeholder="Enter User Name"
                                    />
                                    <div className="flex row">
                                        <InputField
                                            // label="Password"
                                            label={
                                                <MandatoryLabel
                                                    name={"Password"}
                                                    isMandatory={isMandatoryField("password")}
                                                />
                                            }
                                            type={showPassword ? "text" : "password"}
                                            name="password"
                                            onChange={formik.handleChange}
                                            value={formik.values.password}
                                            labelClass="text-textHeading text-[14px]/[20px] mt-[-4px] font-semibold"
                                            inputClass="p-3 h-[44px] border border-borderColorBox rounded-md text-textGray text-[12px]/[24px]"
                                            placeholder="********"
                                        />
                                        <div className="relative">
                                            <button
                                                type="button"
                                                className="absolute right-3 top-12 m-[3] transform -translate-y-1/2 text-gray-500 hover:text-gray-700"
                                                onClick={() => setShowPassword(!showPassword)}
                                            >
                                                {showPassword ? <EyeOpenIcon /> : <EyeClosedIcon />}
                                            </button>
                                        </div>
                                    </div>
                                    <InputField
                                        // label="Port"
                                        label={
                                            <MandatoryLabel
                                                name={"Port"}
                                                isMandatory={isMandatoryField("port")}
                                            />
                                        }
                                        type="text"
                                        name="port"
                                        value={formik.values.port}
                                        onChange={formik.handleChange}
                                        labelClass="text-textHeading text-[14px]/[20px] font-semibold"
                                        inputClass="p-3 h-[44px] border border-borderColorBox rounded-md text-textGray text-[12px]/[24px]"
                                        placeholder="Enter Port"
                                    />
                                    <InputField
                                        // label="Table name"
                                        label={
                                            <MandatoryLabel
                                                name={"Table name"}
                                                isMandatory={isMandatoryField("tableName")}
                                            />
                                        }
                                        name="tableName"
                                        value={formik.values.tableName}
                                        onChange={formik.handleChange}
                                        error={formik.touched.tableName && formik.errors.tableName}
                                        placeholder="Table1"
                                        labelClass="text-textHeading text-[14px]/[20px] font-semibold"
                                        inputClass="p-3 h-[44px] border border-borderColorBox rounded-md text-textGray text-[12px]/[24px]"
                                    />
                                    <InputField
                                        // label="Column name to retrieve date and time"
                                        label={
                                            <MandatoryLabel
                                                name={"Column name to retrieve date and time"}
                                                isMandatory={isMandatoryField("dateTimeColumn")}
                                            />
                                        }
                                        type="text"
                                        name="dateTimeColumn"
                                        value={formik.values.dateTimeColumn}
                                        onChange={formik.handleChange}
                                        labelClass="text-textHeading text-[14px]/[20px] font-semibold"
                                        inputClass="p-3 h-[44px] border border-borderColorBox rounded-md text-textGray text-[12px]/[24px]"
                                        placeholder="Enter DateTime Column"
                                    />
                                    <InputField
                                        // label="User given name for retrieved date and time"
                                        label={
                                            <MandatoryLabel
                                                name={"User given name for retrieved date and time"}
                                                isMandatory={isMandatoryField("Username_dateTimeColumn")}
                                            />
                                        }
                                        type="text"
                                        name="Username_dateTimeColumn"
                                        value={formik.values.Username_dateTimeColumn}
                                        onChange={formik.handleChange}
                                        labelClass="text-textHeading text-[14px]/[20px] font-semibold"
                                        inputClass="p-3 h-[44px] border border-borderColorBox rounded-md text-textGray text-[12px]/[24px]"
                                        placeholder="Enter Value"
                                    />
                                    <InputField
                                        // label="Column name to retrieve batch number"
                                        label={
                                            <MandatoryLabel
                                                name={"Column name to retrieve batch number"}
                                                isMandatory={isMandatoryField("batchNumberColumn")}
                                            />
                                        }
                                        type="text"
                                        name="batchNumberColumn"
                                        value={formik.values.batchNumberColumn}
                                        onChange={formik.handleChange}
                                        labelClass="text-textHeading text-[14px]/[20px] font-semibold"
                                        inputClass="p-3 h-[44px] border border-borderColorBox rounded-md text-textGray text-[12px]/[24px]"
                                        placeholder="Enter Batch Number Column"
                                    />
                                    <InputField
                                        // label="User given name for retrieved batch number"
                                        label={
                                            <MandatoryLabel
                                                name={"User given name for retrieved batch number"}
                                                isMandatory={isMandatoryField("Username_batchNumberColumn")}
                                            />
                                        }
                                        type="text"
                                        name="Username_batchNumberColumn"
                                        value={formik.values.Username_batchNumberColumn}
                                        onChange={formik.handleChange}
                                        labelClass="text-textHeading text-[14px]/[20px] font-semibold"
                                        inputClass="p-3 h-[44px] border border-borderColorBox rounded-md text-textGray text-[12px]/[24px]"
                                        placeholder="Enter Value"
                                    />
                                    <InputField
                                        // label="Column name to retrieve batch description"
                                        label={
                                            <MandatoryLabel
                                                name={"Column name to retrieve batch description"}
                                                isMandatory={isMandatoryField("jobNumberColumn")}
                                            />
                                        }
                                        type="text"
                                        name="jobNumberColumn"
                                        value={formik.values.jobNumberColumn}
                                        onChange={formik.handleChange}
                                        labelClass="text-textHeading text-[14px]/[20px] font-semibold"
                                        inputClass="p-3 h-[44px] border border-borderColorBox rounded-md text-textGray text-[12px]/[24px]"
                                        placeholder="Enter Job Number Column"
                                    />
                                    <InputField
                                        // label="User given name for the retrieved batch description"
                                        label={
                                            <MandatoryLabel
                                                name={"User given name for the retrieved batch description"}
                                                isMandatory={isMandatoryField("Username_jobNumberColumn")}
                                            />
                                        }
                                        type="text"
                                        name="Username_jobNumberColumn"
                                        value={formik.values.Username_jobNumberColumn}
                                        onChange={formik.handleChange}
                                        labelClass="text-textHeading text-[14px]/[20px] font-semibold"
                                        inputClass="p-3 h-[44px] border border-borderColorBox rounded-md text-textGray text-[12px]/[24px]"
                                        placeholder="Enter Value"
                                    />
                                    <InputField
                                        label={
                                            <MandatoryLabel
                                                name={"User given name for the retrieved batch description"}
                                                isMandatory={isMandatoryField("Username_jobNumberColumn")}
                                            />
                                        }
                                        // label="User given name for the retrieved batch description"
                                        type="text"
                                        name="Username_jobNumberColumn"
                                        value={formik.values.Username_jobNumberColumn}
                                        onChange={formik.handleChange}
                                        error={formik.touched.Username_jobNumberColumn && formik.errors.Username_jobNumberColumn}
                                        labelClass="text-textHeading text-[14px]/[20px] font-semibold"
                                        inputClass="p-3 h-[44px] border border-borderColorBox rounded-md text-textGray text-[12px]/[24px]"
                                        placeholder="Enter Value"
                                    />
                                    <InputField
                                        label={
                                            <MandatoryLabel
                                                name={"Column name to retrieve MC group column"}
                                                isMandatory={isMandatoryField("MCGroupColumn")}
                                            />
                                        }
                                        type="text"
                                        name="MCGroupColumn"
                                        value={formik.values.MCGroupColumn}
                                        onChange={formik.handleChange}
                                        error={formik.touched.MCGroupColumn && formik.errors.MCGroupColumn}
                                        labelClass="text-textHeading text-[14px]/[20px] font-semibold"
                                        inputClass="p-3 h-[44px] border border-borderColorBox rounded-md text-textGray text-[12px]/[24px]"
                               placeholder="Enter Value"
                                    />

                                    <InputField
                                        label={
                                            <MandatoryLabel
                                                name={"User given name for the MC group column"}
                                                isMandatory={isMandatoryField("Username_MCGroupColumn")}
                                            />
                                        }
                                        // label="User given name for the retrieved batch description"
                                        type="text"
                                        name="Username_MCGroupColumn"
                                        value={formik.values.Username_MCGroupColumn}
                                        onChange={formik.handleChange}
                                        error={formik.touched.Username_MCGroupColumn && formik.errors.Username_MCGroupColumn}
                                        labelClass="text-textHeading text-[14px]/[20px] font-semibold"
                                        inputClass="p-3 h-[44px] border border-borderColorBox rounded-md text-textGray text-[12px]/[24px]"
                                        placeholder="Enter Value"
                                    />

                                </div>
                            </div>

                            {/* Footer */}
                            <div className="flex w-full gap-x-4 mt-2 justify-end">
                                <button
                                    type="button"
                                    className="w-24 h-10 flex justify-center items-center border border-borderColorBox rounded-md text-customGray text-[14px]"
                                    onClick={navigateBack}
                                >
                                    Cancel
                                </button>

                                <button
                                    type="submit"
                                    className={`flex justify-center items-center w-auto text-nowrap px-3 h-10 bg-baseOrange text-[14px] text-white rounded-md }`}
                                    style={{ borderRadius: "5px" }}
                                >
                                    Edit and Save
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
            <Notification
                open={notificationOpen}
                message={notificationMessage}
                severity={notificationSeverity}
                onClose={handleNotificationClose}
            />
        </>
    );
};

export default EditDBConnection;
